import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './frontend/includes/Header';
import Footer from './frontend/includes/Footer';
import Contact from './frontend/Contact';
import Home from './frontend/Home';
import TopUp from './frontend/TopUp';
import TopUpDetails from './frontend/TopUpDetails';
import Search from './frontend/Search';
import Logout from './frontend/Logout';
import Profile from './frontend/Profile';
import Order from './frontend/Order';
import MyCodes from './frontend/MyCodes';
import AddMoney from './frontend/AddMoney';
import ProfileAddMoney from './frontend/ProfileAddMoney';
import Login from './frontend/Login';
import Register from './frontend/Register';
import ForgotPassword from './frontend/ForgotPassword';
import ResetPassword from './frontend/ResetPassword';
import PaymentPage from './frontend/PaymentPage';
import NagadCheckout from './frontend/NagadCheckout';
import BkashCheckout from './frontend/BkashCheckout';
import Payment from './frontend/Payment';
import PaymentSuccess from './frontend/PaymentSuccess';
import PaymentCancel from './frontend/PaymentCancel';
import WalletTopUpPaymentSuccess from './frontend/WalletTopUpPaymentSuccess';
import Transactions from './frontend/Transactions';




function App() {
  const [searchData, setSearchData] = useState([]);

  // user login
  let user = JSON.parse(localStorage.getItem('user-info'));
  let userId = user ? user.id : '';

  function userUpdate() {
    user = JSON.parse(localStorage.getItem('user-info'));
    userId = user ? user.id : '';
  }

  return (
    <BrowserRouter>
      <Header setSearchData={setSearchData} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/topup" element={<TopUp />} />
        <Route path="/login" element={<Login userUpdate={userUpdate} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/topup/:code" element={<TopUpDetails />} />
        <Route path="/search" element={<Search searchData={searchData} />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/order" element={<Order />} />
        <Route path="/profile/my-codes" element={<MyCodes />} />
        <Route path="/profile/add-money" element={<ProfileAddMoney />} />
        <Route path="/topup/add-money" element={<AddMoney />} />
        <Route path="/topup/make-payment" element={<PaymentPage />} />
        <Route path="/checkout/nagad" element={<NagadCheckout />} />
        <Route path="/checkout/bkash" element={<BkashCheckout />} />
        <Route path="/checkout/payment" element={<Payment />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/wallet-topup-payment-success" element={<WalletTopUpPaymentSuccess />} />
        <Route path="/payment-cancel" element={<PaymentCancel />} />
        <Route path="/profile/transactions" element={<Transactions />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
