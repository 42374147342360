import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import userPassport from '../../assets/img/default.png'; // Adjust the path as needed

export default function Sidebar() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const user = JSON.parse(localStorage.getItem('user-info'));

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyle = isMobile
        ? { paddingTop: '18%' } // Adjust marginBottom for mobile
        : { paddingTop: '5%' }; // Adjust marginBottom for desktop15
    return (

        <>
            {
                user &&
                < div id="userMenu" className="bg-white rounded shadow-md absolute mt-12 top-0 right-0 min-w-full overflow-auto z-30">
                    <nav className="flex fixed items-center justify-between h-16 bg-white text-gray-700 border-b border-gray-200 z-10 srgameshop-navlist" style={{ position: 'fixed', bottom: '0px' }}>
                        <div className="z-10 fixed inset-0 transition-opacity">
                            <div tabindex="0" className="absolute inset-0 bg-black opacity-50">
                            </div>
                        </div>
                        <aside className="transform top-0 right-0 w-64 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 translate-x-0" style={containerStyle}>
                            <button id="userButton" className="flex items-center focus:outline-none p-3">
                                <img
                                    src={userPassport}
                                    style={{ height: '50px', backgroundColor: '#D81C4B', color: '#fff' }}
                                    alt="Profile"
                                />
                                <div>
                                    <div className="text-left w-full">
                                        <span className="px-3 font-normal font-primary">Hi, {user.name.length > 10 ? `${user.name.substring(0, 10)}...` : user.name}
                                        </span>
                                    </div>
                                    <div className="text-left">
                                        <span className="px-3">{user.email}</span>
                                    </div>
                                </div>
                            </button>
                            <div className="w-full mx-auto text-center">
                                <Link to="/logout" type="button" className="focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 px-2.5 py-1.5 shadow-sm text-white dark:text-gray-900 bg-primary-500 hover:bg-primary-600 disabled:bg-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:disabled:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 dark:focus-visible:outline-primary-400 inline-flex items-center mb-2">
                                    <span className="flex items-center justify-center p-0"><span className="mr-2"><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="power-off" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: '0.83rem' }}>
                                        <path fill="currentColor" d="M388.5 46.3C457.9 90.3 504 167.8 504 256c0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 168 54 90.3 123.5 46.3c5.8-3.7 13.5-1.8 16.9 4.2l11.8 20.9c3.1 5.5 1.4 12.5-3.9 15.9C92.8 122.9 56 185.1 56 256c0 110.5 89.5 200 200 200s200-89.5 200-200c0-70.9-36.8-133.1-92.3-168.6-5.3-3.4-7-10.4-3.9-15.9l11.8-20.9c3.3-6.1 11.1-7.9 16.9-4.3zM280 276V12c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v264c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12z"></path>
                                    </svg></span><span className="no-underline text-xs">Logout</span></span>

                                </Link>
                            </div>
                            <hr />
                            <a href="/profile" className=""><span className="flex items-center p-4 font-primary"><span className="mr-2"><svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" className="w-6 h-6">
                                <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                            </svg></span> My Account </span></a>
                            <a href="/profile/order" className="text-gray-900 no-underline"><span className="flex items-center p-4 font-primary"><span className="mr-2"><svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24" className="w-6 h-6">
                                <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path>
                            </svg></span> My Orders </span></a>
                            <a href="/profile/my-codes" className="text-gray-900 no-underline"><span className="flex items-center p-4 font-primary"><span className="mr-2"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" className="css-i6dzq1">
                                <rect x="3" y="3" width="7" height="7"></rect>
                                <rect x="14" y="3" width="7" height="7"></rect>
                                <rect x="14" y="14" width="7" height="7"></rect>
                                <rect x="3" y="14" width="7" height="7"></rect>
                            </svg></span><span> My Codes </span></span></a>
                            <a href="/profile/transactions" className="text-gray-900 no-underline"><span className="flex items-center p-4 font-primary"><span className="mr-2"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" className="css-i6dzq1">
                                <line x1="8" y1="6" x2="21" y2="6"></line>
                                <line x1="8" y1="12" x2="21" y2="12"></line>
                                <line x1="8" y1="18" x2="21" y2="18"></line>
                                <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                <line x1="3" y1="18" x2="3.01" y2="18"></line>
                            </svg></span> My Transaction </span></a>
                            <a href="/profile/add-money" className="text-gray-900 no-underline"><span className="flex items-center p-4 font-primary"><span className="mr-2"><svg className="w-6 h-6" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M3 0V3H0V5H3V8H5V5H8V3H5V0H3M10 3V5H19V7H13C11.9 7 11 7.9 11 9V15C11 16.1 11.9 17 13 17H19V19H5V10H3V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V16.72C21.59 16.37 22 15.74 22 15V9C22 8.26 21.59 7.63 21 7.28V5C21 3.9 20.1 3 19 3H10M13 9H20V15H13V9M16 10.5A1.5 1.5 0 0 0 14.5 12A1.5 1.5 0 0 0 16 13.5A1.5 1.5 0 0 0 17.5 12A1.5 1.5 0 0 0 16 10.5Z"></path>
                            </svg></span> Add Money </span></a>
                            <a href="/contact" className="text-gray-900 no-underline"><span className="flex items-center p-4 font-primary"><span className="mr-2"><svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" className="w-6 h-6">
                                <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg></span><span> Contact Us </span></span></a>
                            <hr />
                            <div className="w-full mx-auto text-center mt-3">
                                <a href="https://wa.me/+8801317956376" target="_blank" rel="noopener noreferrer" className="align-middle bg-pink-500 rounded-full mx-auto text-center hover:bg-pink-400 text-center px-1 py-2 text-white text-sm font-semibold rounded-lg inline-block shadow-lg w-32 px-6 mb-2 d-block btn-primary srgameshop-btn">
                                    <span className="flex items-center justify-center">
                                        <span className="mr-2">
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4 1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z"></path>
                                            </svg>
                                        </span>
                                        <span className="no-underline">Support</span>
                                    </span>
                                </a>

                            </div>
                        </aside>
                    </nav>
                </div >
            }
        </>

    )
}