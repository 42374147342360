import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import config from '../config';

export default function Login({ userUpdate }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/profile";
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    useEffect(() => {
        localStorage.clear(); // Clears all data stored in local storage
        sessionStorage.clear(); // Clears all data stored in session storage
    }, []);

    async function signIn(e) {
        e.preventDefault(); // Prevent form submission
        setLoading(true);
        let item = { email, password };

        let result = await fetch(`${config.baseURL}api/user_login`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(item)
        });

        result = await result.json();

        if (result.error) {
            setErrors(result.error);
            setLoading(false);
        } else {
            localStorage.setItem('user-info', JSON.stringify(result));
            userUpdate();
            navigate(from, { replace: true });
        }
    }

    const googleLogin = () => {
        setLoading2(true);
        window.location.href = `${config.baseURL}api/auth/google`;
    };

    // Update to handle Google OAuth callback
    useEffect(() => {
        const handleGoogleCallback = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('email');

                if (!code) {
                    throw new Error('No authorization code found.');
                }

                const response = await fetch(`${config.baseURL}api/auth/google/callbacklogin?email=${code}`);
                const data = await response.json();

                if (response.ok) {
                    localStorage.setItem('user-info', JSON.stringify(data));
                    userUpdate();
                    navigate('/profile');
                } else {
                    setErrors([data.error || 'Failed to login with Google. Please try again.']);
                }
            } catch (error) {
                console.error('Error fetching user data after Google login:', error);
                setErrors(['Failed to login with Google. Please try again.']);
            }
        };

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('email');
        if (code) {
            handleGoogleCallback();
        }
    }, [userUpdate, navigate]);


    return (
        <>

            <div className="secondary-section body-content" style={{ marginTop: "3%" }} >
                <div className="login-form px-4 md:px-2 pb-3 mx-auto">
                    <div className="w-auto px-0 md:px-3 pt-5 pb-1">
                        <h1 className="text-2xl font-bold">Login</h1>
                        <div className="text-center my-3">
                            <div className="social-login">
                                <button
                                    type="button"
                                    onClick={googleLogin}
                                    disabled={loading2}
                                    className="focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 px-2.5 py-2.5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 text-gray-900 dark:text-white bg-white hover:bg-gray-50 disabled:bg-white dark:bg-gray-900 dark:hover:bg-gray-800/50 dark:disabled:bg-gray-900 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400 inline-flex items-center"
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mr-2"
                                    >
                                        <g fill="#000" fillRule="evenodd">
                                            <path
                                                d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                                                fill="#EA4335"
                                            ></path>
                                            <path
                                                d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c-1.7-1.57 2.68-3.88 2.68-6.62z"
                                                fill="#4285F4"
                                            ></path>
                                            <path
                                                d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                                                fill="#FBBC05"
                                            ></path>
                                            <path
                                                d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                                                fill="#34A853"
                                            ></path>
                                            <path fill="none" d="M0 0h18v18H0z"></path>
                                        </g>
                                    </svg>
                                    {loading2 ? (
                                        <div className="flex items-center">
                                            <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2" style={{ borderBottomCcolor: 'red', borderLeftColor: 'red' }} />
                                            Loading
                                        </div>
                                    ) : (
                                        "Login with Google"
                                    )}

                                </button>
                                {/* Add similar buttons for other social logins */}
                            </div>
                            <div className="flex justify-between items-center pt-5">
                                <hr className="w-1/5 px-2" />
                                <h1 className="text-gray-500 w-3/5 font-primary px-2 text-sm">
                                    Or sign in with credentials
                                </h1>
                                <hr className="w-1/5 px-2" />

                            </div>
                        </div>
                        {errors.length > 0 && (
                            <div className="alert alert-danger relative" style={{ backgroundColor: '#ffcccc', color: '#cc0000', textAlign: 'center', padding: '10px', borderRadius: '5px' }}>
                                <button
                                    className="absolute top-0 right-0 m-2 focus:outline-none"
                                    onClick={() => setErrors([])}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 cursor-pointer"
                                        viewBox="0 0 20 20"
                                        style={{ fill: '#cc0000' }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M14.95 5.95a.75.75 0 0 1 0 1.06l-4.95 4.95 4.95 4.95a.75.75 0 0 1-1.06 1.06l-4.95-4.95-4.95 4.95a.75.75 0 0 1-1.06-1.06l4.95-4.95-4.95-4.95a.75.75 0 0 1 1.06-1.06l4.95 4.95 4.95-4.95a.75.75 0 0 1 1.06 0z"
                                        />
                                    </svg>
                                </button>
                                {errors.map((error, index) => (
                                    <p key={index}>{error}</p>
                                ))}
                            </div>
                        )}

                        <form>
                            <div className="my-2 relative">
                                <div className="relative">
                                    <label className="font-primary font-normal">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Email"
                                        className="form-input relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-2.5 shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset dark:ring-black-900 focus:ring-2 focus:ring-black-900 dark:focus:ring-black-900"
                                    />
                                </div>
                            </div>
                            <div className="my-2 relative">
                                <div className="relative">
                                    <label className="font-primary font-normal">Password</label>
                                    <input
                                        autoComplete="off"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password"
                                        placeholder="Password"
                                        className="form-input relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-2.5 shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset dark:ring-black-900 focus:ring-2 focus:ring-black-900 dark:focus:ring-black-900"
                                    />
                                </div>
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="justify-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 px-2.5 py-2.5 shadow-sm text-white dark:text-gray-900 bg-primary-500 hover:bg-primary-600 disabled:bg-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:disabled:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 dark:focus-visible:outline-primary-400 inline-flex items-center my-2 w-full text-center"
                                    onClick={signIn}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <div className="flex items-center">
                                            <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2" />
                                            Loading
                                        </div>
                                    ) : (
                                        "Login"
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="mb-5 text-center subtitle-4 font-primary font-normal game-name">
                        New Member?{' '}
                        <Link to="/register" className="text-pink-500 font-primary font-normal">
                            Register
                        </Link>{' '}<br />

                        <Link to="/forgot-password" className="text-pink-500 font-primary font-normal">
                            Forgot Password?{' '}
                        </Link>{' '}
                    </div>
                </div>
            </div>


        </>
    );
}
