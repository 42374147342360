import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import config from '../config';

export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // State to track loading

    async function handleForgotPassword() {
        setIsLoading(true); // Set loading to true when button is clicked

        let item = { email };

        let result = await fetch(`${config.baseURL}api/forgot_password`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(item)
        });

        result = await result.json();

        if (result.error) {
            setErrors(result.error);
        } else {
            setMessage(result.message);
        }

        setIsLoading(false); // Reset loading state after request completes
    }
    return (
        <>

            <div className="secondary-section body-content" style={{ marginTop: "12%" }}>
                <div className="login-form px-4 md:px-2 pb-3 mx-auto">
                    <div className="w-auto px-0 md:px-3 pt-5 pb-1">
                        <h1 className="text-2xl font-bold">Reset Password</h1>
                        {errors && errors.length > 0 && (
                            <div className="alert alert-danger relative" style={{ backgroundColor: '#ffcccc', color: '#cc0000', textAlign: 'center', padding: '10px', borderRadius: '5px' }}>
                                <button
                                    className="absolute top-0 right-0 m-2 focus:outline-none"
                                    onClick={() => setErrors([])}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 cursor-pointer"
                                        viewBox="0 0 20 20"
                                        style={{ fill: '#cc0000' }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M14.95 5.95a.75.75 0 0 1 0 1.06l-4.95 4.95 4.95 4.95a.75.75 0 0 1-1.06 1.06l-4.95-4.95-4.95 4.95a.75.75 0 0 1-1.06-1.06l4.95-4.95-4.95-4.95a.75.75 0 0 1 1.06-1.06l4.95 4.95 4.95-4.95a.75.75 0 0 1 1.06 0z"
                                        />
                                    </svg>
                                </button>
                                {errors.map((error, index) => (
                                    <p key={index}>{error}</p>
                                ))}
                            </div>
                        )}
                        {message && (
                            <div className="alert alert-success relative" style={{ backgroundColor: '#d4edda', borderColor: '#c3e6cb', color: '#155724', textAlign: 'center', padding: '10px', borderRadius: '5px' }}>
                                <p>{message}</p>
                            </div>
                        )}
                        <div className="my-2 relative">
                            <div className="relative">
                                <label className="font-primary font-normal">Email</label>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-input relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-2.5 shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset dark:ring-black-900 focus:ring-2 focus:ring-black-900 dark:focus:ring-black-900"
                                />
                            </div>
                        </div>

                        <div className="text-center">

                            {/* Conditionally render loading icon or button content */}
                            {isLoading ? (
                                <button
                                    className="justify-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 px-2.5 py-2.5 shadow-sm text-white dark:text-gray-900 bg-primary-500 hover:bg-primary-600 disabled:bg-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:disabled:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 dark:focus-visible:outline-primary-400 inline-flex items-center my-2 w-full text-center"
                                >
                                    <div className="flex items-center">
                                        <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2" />
                                        Loading
                                    </div>
                                </button>
                            ) : (
                                <button type="submit"
                                    className="justify-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 px-2.5 py-2.5 shadow-sm text-white dark:text-gray-900 bg-primary-500 hover:bg-primary-600 disabled:bg-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:disabled:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 dark:focus-visible:outline-primary-400 inline-flex items-center my-2 w-full text-center"
                                    onClick={handleForgotPassword}>Submit</button>
                            )}
                        </div>
                    </div>
                    <div className="mb-5 text-center subtitle-4 font-primary font-normal game-name">
                        <Link to="/login" className="text-pink-500 font-primary font-normal">
                            Back to Login
                        </Link>

                    </div>
                </div>
            </div>


        </>
    );
}
