import React, { useEffect, useState } from 'react';
import config from './../config';

export default function Payment() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState('');
    const [userId, setUserId] = useState('');
    const [orderId, setOrderId] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    const handlePayment = async () => {
        try {
            const response = await fetch(`${config.baseURL}api/payment/initiate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ full_name: fullName, email, amount, user_id: userId, order_id: orderId })
            });
            const data = await response.json();
            if (data.payment_url) {
                window.location.href = data.payment_url;
            } else {
                console.error('Payment initiation failed', data);
            }
        } catch (error) {
            console.error('Payment initiation failed', error);
        }
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyle = isMobile
        ? { marginTop: '15%', fontFamily: "'Baloo Da 2', cursive" } // Adjust marginBottom for mobile
        : { marginTop: '15%', fontFamily: "'Baloo Da 2', cursive" }; // Adjust marginBottom for desktop15


    return (
        <div className="w-full min-h-screen sm:h-auto sm:p-12 sm:flex sm:items-center sm:justify-center" style={containerStyle}>
            <h2>Initiate Payment</h2>
            <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Full Name"
            />
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
            />
            <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount"
            />
            <input
                type="number"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                placeholder="User ID"
            />
            <input
                type="number"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                placeholder="Order ID"
            />
            <button onClick={handlePayment}>Pay Now</button>
        </div>
    );
}
