import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from './../config';
import HomePageModal from './HomePageModal'; // Adjust the import path as needed

export default function Home() {
    const [slideIndex, setSlideIndex] = useState(1);
    const slideWidth = 100; // Adjust as needed based on your carousel item width
    const [siteSettings, setSiteSettings] = useState(null);
    const [isNotificationVisible, setIsNotificationVisible] = useState(true); // Add state for notification visibility
    const [isNotificationVisibleModal, setIsNotificationVisibleModal] = useState(true); // Add state for notification visibility

    const [lastNoticeData, setLastNoticeData] = useState(null); // Store the last notice data
    // Hook to get the current path
    const location = useLocation();
    // Function to go to the next slide
    const nextSlide = () => {
        const newIndex = (slideIndex + 1) % 4; // Assuming there are 4 slides
        setSlideIndex(newIndex);
    };

    // Effect to handle automatic sliding
    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000); // Slide every 5 seconds (5000 milliseconds)

        // Clear interval on component unmount to avoid memory leaks
        return () => clearInterval(interval);
    }, [slideIndex]); // Re-run effect when slideIndex changes

    // Function to go to a specific slide
    const goToSlide = (index) => {
        setSlideIndex(index);
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyle = isMobile
        ? { marginTop: '15%' } // Adjust marginBottom for mobile
        : { marginTop: '4%' }; // Adjust marginBottom for desktop15

    // Search implementation
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const fetchSiteSettings = async () => {
        try {
            const response = await fetch(`${config.baseURL}api/frontend-settings`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setSiteSettings(data.site_settings);
            // Check if the notice data has changed
            const storedNoticeData = localStorage.getItem('noticeData');
            if (storedNoticeData && storedNoticeData !== JSON.stringify(data.site_settings.notice_popup)) {
                localStorage.removeItem('notificationDismissalDate'); // Reset dismissal date if notice data has changed
            }
            setLastNoticeData(data.site_settings.notice_popup);
            localStorage.setItem('noticeData', JSON.stringify(data.site_settings.notice_popup));
            localStorage.setItem('bannerData', JSON.stringify(data.site_settings.banner_images));

        } catch (error) {
            console.error('Error fetching site settings:', error);
            return null;
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                let result = await fetch(`${config.baseURL}api/?page=${currentPage}`);
                result = await result.json();
                setData(result.data); // Adjust based on your API response structure
                localStorage.setItem('productData', JSON.stringify(result.data));

                const storedNoticeData = localStorage.getItem('noticeData');
                const storedBannerData = localStorage.getItem('bannerData');
                const storedProductData = localStorage.getItem('productData');

                if (storedNoticeData !== JSON.stringify(data.site_settings.notice_popup) ||
                    storedBannerData !== JSON.stringify(data.site_settings.banner_images) ||
                    storedProductData !== JSON.stringify(data.products)) {

                    // Update local storage with new data
                    localStorage.setItem('noticeData', JSON.stringify(data.site_settings.notice_popup));
                    localStorage.setItem('bannerData', JSON.stringify(data.site_settings.banner_images));
                    localStorage.setItem('productData', JSON.stringify(data.products));

                    // Clear the notification dismissal date to show the notification again
                    localStorage.removeItem('notificationDismissalDate');

                    // Proceed to check cache version and possibly reload
                    checkCacheVersion();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [currentPage]);

    useEffect(() => {
        fetchSiteSettings();
    }, []);

    const slides = siteSettings?.banner_images || [];
    const totalSlides = slides.length + 2; // +2 for duplicated slides
    // Function to hide the notification
    const closeNotification = () => {
        setIsNotificationVisible(false);
    };
    useEffect(() => {
        const checkNotificationVisibility = () => {
            const lastDismissalDate = localStorage.getItem('notificationDismissalDate');
            const today = new Date().toISOString().split('T')[0]; // Format date as YYYY-MM-DD

            if (lastDismissalDate === today) {
                setIsNotificationVisibleModal(false);
            }
        };

        checkNotificationVisibility();
    }, []);

    // Function to hide the notification and store dismissal date
    const closeNotificationModal = () => {
        setIsNotificationVisibleModal(false);
        const today = new Date().toISOString().split('T')[0]; // Format date as YYYY-MM-DD
        localStorage.setItem('notificationDismissalDate', today);
    };

    const checkCacheVersion = async () => {
        try {
            const response = await fetch(`${config.baseURL}api/cache-version`);
            const data = await response.json();
            const cachedVersion = localStorage.getItem('cacheVersion');

            if (cachedVersion !== data.cache_version.toString()) {
                localStorage.setItem('cacheVersion', data.cache_version.toString());
                window.location.reload();
            }
        } catch (error) {
            console.error('Error checking cache version:', error);
        }
    };

    return (
        <>
            {location.pathname === '/' && isNotificationVisibleModal && (
                <HomePageModal onClose={closeNotificationModal} />
            )}
            <head>

            </head>
            <div>
                <div className="p-2" style={containerStyle}>
                    {siteSettings?.notice?.status === '1' && isNotificationVisible && (
                        <section>
                            <div className="container mx-auto px-2 py-1 md:p-5 md:py-3" style={{ background: 'rgb(255, 100, 0)' }}>
                                <div className="flex items-center justify-between">
                                    <h2 className="font-normal text-white" style={{ fontSize: '1.2rem' }}>Notice:</h2>
                                    <svg
                                        viewBox="0 0 24 24"
                                        style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                        onClick={closeNotification} // Attach click handler
                                    >
                                        <path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z"></path>
                                    </svg>
                                </div>
                                <div className="mt-3 flex">
                                    <span className="font-normal text-white" style={{ fontSize: '12px' }}>
                                        {siteSettings.notice.message ? (
                                            <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: siteSettings.notice.message }} />
                                        ) : (
                                            'No message available'
                                        )}
                                    </span>
                                </div>
                            </div>
                        </section>
                    )}

                    <section className="container m-auto">
                        <section className="carousel is-hover my-2 md:mt-10" dir="ltr" aria-label="Gallery" tabIndex="0">
                            <div className="carousel__viewport">
                                <ol className="carousel__track" style={{ transform: `translateX(-${slideIndex * slideWidth}%)`, transition: 'transform 0.5s ease-in-out' }}>
                                    {/* Duplicated slides */}
                                    <li className="carousel__slide" aria-hidden="true" style={{ width: '100%' }}>
                                        <div className="carousel__item">
                                            <img
                                                src={`${config.baseURL}storage/banner_images/${slides[slides.length - 1]}`}
                                                alt={`Slide ${totalSlides - 1}`}
                                                className="rounded-md"
                                            />
                                        </div>
                                    </li>
                                    {slides.map((image, index) => (
                                        <li key={index} className="carousel__slide" aria-hidden="true" style={{ width: '100%' }}>
                                            <div className="carousel__item">
                                                <img
                                                    src={`${config.baseURL}storage/banner_images/${image}`}
                                                    alt={`Slide ${index + 1}`}
                                                    className="rounded-md"
                                                />
                                            </div>
                                        </li>
                                    ))}
                                    {/* Duplicated slides */}
                                    <li className="carousel__slide" aria-hidden="true" style={{ width: '100%' }}>
                                        <div className="carousel__item">
                                            <img
                                                src={`${config.baseURL}storage/banner_images/${slides[0]}`}
                                                alt={`Slide 0`}
                                                className="rounded-md"
                                            />
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <button type="button" className="carousel__prev" aria-label="Navigate to previous slide" onClick={() => goToSlide(slideIndex - 1)}>
                                <svg className="carousel__icon" viewBox="0 0 24 24" role="img" aria-label="Arrow pointing to the left">
                                    <title>Arrow pointing to the left</title>
                                    <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
                                </svg>
                            </button>
                            <button type="button" className="carousel__next" aria-label="Navigate to next slide" onClick={() => goToSlide(slideIndex + 1)}>
                                <svg className="carousel__icon" viewBox="0 0 24 24" role="img" aria-label="Arrow pointing to the right">
                                    <title>Arrow pointing to the right</title>
                                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                                </svg>
                            </button>
                            <ol className="carousel__pagination">
                                {siteSettings?.banner_images.map((_, index) => (
                                    <li key={index} className={`carousel__pagination-item ${slideIndex === index ? 'carousel__pagination-button--active' : ''}`}>
                                        <button type="button" className="carousel__pagination-button" aria-label={`Navigate to slide ${index + 1}`} onClick={() => goToSlide(index)}></button>
                                    </li>
                                ))}
                            </ol>
                            <div className="carousel__liveregion carousel__sr-only" aria-live="polite" aria-atomic="true">Item {slideIndex + 1} of 4</div>
                        </section>
                    </section >
                    <section className="md:my-10 my-3">
                        <div className="container mx-auto">
                            {/* MOBILE GAMES TOP UP */}
                            <div className="text-center mb-6">
                                <div className="flex items-center justify-center px-4 mt-0 md:mt-2 section-contact-gap py-2 pb-4 md:py-8">
                                    <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                                    <h3 className="text-2xl sm:text-3xl text-center font-primary font-bold mx-4 text-secondary-900">MOBILE GAMES TOP UP</h3>
                                    <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                                </div>
                            </div>
                            <div className="pb-1 md:pb-10">
                                <div className="md:py-5 md:px-0 grid md:grid-cols-6 sm:grid-cols-4 grid-cols-3 md:gap-8 gap-4">
                                    {data.filter(item => JSON.parse(item.input_fields).is_quantity_selection_allowed === 'NO').map((item) => (
                                        <div className="single-game-product mb-2 md:mb-6" key={item.id}>
                                            <a href={`/topup/${item.code}`} className="triangle">
                                                <div className="cursor-pointer">
                                                    <div className="inset-0 opacity-25"></div>
                                                    <div className="inset-0 transform hover:scale-90 transition duration-300">
                                                        <div className="h-full w-full text-center mx-auto">
                                                            <img
                                                                src={`${config.baseURL}storage/products/${item.gallery}`}
                                                                width="200"
                                                                height="100"
                                                                alt="Product Img"
                                                                data-nuxt-img=""
                                                                sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 400px"
                                                                srcSet={`
                                                ${config.baseURL}storage/products/${item.gallery} 1w,
                                                ${config.baseURL}storage/products/${item.gallery} 2w,
                                                ${config.baseURL}storage/products/${item.gallery} 320w,
                                                ${config.baseURL}storage/products/${item.gallery} 400w,
                                                ${config.baseURL}storage/products/${item.gallery} 640w,
                                                ${config.baseURL}storage/products/${item.gallery} 800w
                                            `}
                                                                className="rounded-md"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h1 className="capitalize text-xs text-center pt-3 font-primary font-extralight text-secondary-500">{item.name}</h1>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Gift Cards/Vouchers */}
                            <div className="text-center mb-6">
                                <div className="flex items-center justify-center px-4 mt-0 md:mt-2 section-contact-gap py-2 pb-4 md:py-8">
                                    <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                                    <h3 className="text-2xl sm:text-3xl text-center font-primary font-bold mx-4 text-secondary-900">Gift Cards/Vouchers</h3>
                                    <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                                </div>
                            </div>
                            <div className="pb-1 md:pb-10">
                                <div className="md:py-5 md:px-0 grid md:grid-cols-6 sm:grid-cols-4 grid-cols-3 md:gap-8 gap-4">
                                    {data.filter(item => JSON.parse(item.input_fields).is_quantity_selection_allowed === 'YES').map((item) => (
                                        <div className="single-game-product mb-2 md:mb-6" key={item.id}>
                                            <a href={`/topup/${item.code}`} className="triangle">
                                                <div className="cursor-pointer">
                                                    <div className="inset-0 opacity-25"></div>
                                                    <div className="inset-0 transform hover:scale-90 transition duration-300">
                                                        <div className="h-full w-full text-center mx-auto">
                                                            <img
                                                                src={`${config.baseURL}storage/products/${item.gallery}`}
                                                                width="200"
                                                                height="100"
                                                                alt="Product Img"
                                                                data-nuxt-img=""
                                                                sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 400px"
                                                                srcSet={`
                                                ${config.baseURL}storage/products/${item.gallery} 1w,
                                                ${config.baseURL}storage/products/${item.gallery} 2w,
                                                ${config.baseURL}storage/products/${item.gallery} 320w,
                                                ${config.baseURL}storage/products/${item.gallery} 400w,
                                                ${config.baseURL}storage/products/${item.gallery} 640w,
                                                ${config.baseURL}storage/products/${item.gallery} 800w
                                            `}
                                                                className="rounded-md"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h1 className="capitalize text-xs text-center pt-3 font-primary font-extralight text-secondary-500">{item.name}</h1>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Pagination */}
                            <div className="flex items-center justify-center px-4 mt-0 md:mt-2 section-contact-gap py-2 pb-4 md:py-8">
                                <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />

                                <button
                                    className="text-2xl sm:text-3xl text-center font-primary font-bold mx-4 text-secondary-900"
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => setCurrentPage(prevPage => prevPage > 1 ? prevPage - 1 : 1)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-arrow-left"
                                        viewBox="0 0 16 16"
                                        style={{ marginRight: '4px' }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.854 8.354a.5.5 0 0 0 0 .707L8.793 12.5a.5.5 0 0 0 .707-.707L7.207 8l2.293-2.293a.5.5 0 0 0-.707-.707L5.147 7.646a.5.5 0 0 0 0 .708l3.293 3.293a.5.5 0 0 0 .708-.708L5.854 8.354z"
                                        />
                                    </svg>
                                    Previous
                                </button>
                                <button
                                    className="text-2xl sm:text-3xl text-center font-primary font-bold mx-4 text-secondary-900"
                                    style={{ marginLeft: '4px', display: 'flex', alignItems: 'center' }}
                                    onClick={() => setCurrentPage(prevPage => prevPage + 1)}
                                >
                                    Next
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-arrow-right"
                                        viewBox="0 0 16 16"
                                        style={{ marginLeft: '4px' }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10.646 8.354a.5.5 0 0 0 0-.707L7.707 4.5a.5.5 0 0 0-.707.707L9.293 8 7.707 9.586a.5.5 0 0 0 .707.707l2.293-2.293a.5.5 0 0 0 0-.708l-3.293-3.293a.5.5 0 0 0-.708.708L10.646 8.354z"
                                        />
                                    </svg>
                                </button>
                                <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                            </div>
                        </div>
                    </section>


                    <section className="md:mb-10">
                        <div className="my-5 md:my-10">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.srgameshop.app" className="flex items-center" style={{
                                textDecoration: 'none',
                                background: '#FFE7FF',
                                maxWidth: '420px',
                                borderRadius: '9px',
                                padding: '14px 20px',
                                margin: 'auto',
                                marginBottom: '60px',
                                border: '2px solid #de2d5999'
                            }}>
                                <img style={{ height: '70px' }} className="mr-5" src="https://srgameshop.com/_nuxt/google-play.d70185d8.png" alt="" />
                                <div>
                                    <h4 data-v-aa72d52a="" className="font-weight-medium game-name" style={{ fontSize: '16px !important' }}>Download our mobile app now</h4>
                                    <p id="downloadnow" className="mb-0" style={{ fontWeight: '600 !important' }}>Download Now →</p>
                                </div>
                            </a >
                        </div >
                    </section >
                </div >
            </div >
        </>
    );
}
