import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from './../config';



export default function TopUp() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyle = isMobile
        ? { marginTop: '15%' } // Adjust marginBottom for mobile
        : { marginTop: '4%' }; // Adjust marginBottom for desktop15

    //search implementation
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        async function fetchData() {
            try {
                //(`${config.baseURL}api/search/${query}`);
                let result = await fetch(`${config.baseURL}api/?page=${currentPage}`);
                result = await result.json();
                setData(result.data); // Adjust based on your API response structure
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [currentPage]);
    return (
        <>
            <div className="mx-auto sm:px-6 lg:px-8" style={containerStyle}>
                <div className="md:mt-8 mt-2 overflow-hidden">
                    <section className="md:my-10 my-3">
                        <div className="container mx-auto">
                            {/* MOBILE GAMES TOP UP */}
                            <div className="text-center mb-6">
                                <div className="flex items-center justify-center px-4 mt-0 md:mt-2 section-contact-gap py-2 pb-4 md:py-8">
                                    <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                                    <h3 className="text-2xl sm:text-3xl text-center font-primary font-bold mx-4 text-secondary-900">MOBILE GAMES TOP UP</h3>
                                    <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                                </div>
                            </div>
                            <div className="pb-1 md:pb-10">
                                <div className="md:py-5 md:px-0 grid md:grid-cols-6 sm:grid-cols-4 grid-cols-3 md:gap-8 gap-4">
                                    {data.filter(item => JSON.parse(item.input_fields).is_quantity_selection_allowed === 'NO').map((item) => (
                                        <div className="single-game-product mb-2 md:mb-6" key={item.id}>
                                            <a href={`/topup/${item.code}`} className="triangle">
                                                <div className="cursor-pointer">
                                                    <div className="inset-0 opacity-25"></div>
                                                    <div className="inset-0 transform hover:scale-90 transition duration-300">
                                                        <div className="h-full w-full text-center mx-auto">
                                                            <img
                                                                src={`${config.baseURL}storage/products/${item.gallery}`}
                                                                width="200"
                                                                height="100"
                                                                alt="Product Img"
                                                                data-nuxt-img=""
                                                                sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 400px"
                                                                srcSet={`
                                                ${config.baseURL}storage/products/${item.gallery} 1w,
                                                ${config.baseURL}storage/products/${item.gallery} 2w,
                                                ${config.baseURL}storage/products/${item.gallery} 320w,
                                                ${config.baseURL}storage/products/${item.gallery} 400w,
                                                ${config.baseURL}storage/products/${item.gallery} 640w,
                                                ${config.baseURL}storage/products/${item.gallery} 800w
                                            `}
                                                                className="rounded-md"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h1 className="capitalize text-xs text-center pt-3 font-primary font-extralight text-secondary-500">{item.name}</h1>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Gift Cards/Vouchers */}
                            <div className="text-center mb-6">
                                <div className="flex items-center justify-center px-4 mt-0 md:mt-2 section-contact-gap py-2 pb-4 md:py-8">
                                    <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                                    <h3 className="text-2xl sm:text-3xl text-center font-primary font-bold mx-4 text-secondary-900">Gift Cards/Vouchers</h3>
                                    <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                                </div>
                            </div>
                            <div className="pb-1 md:pb-10">
                                <div className="md:py-5 md:px-0 grid md:grid-cols-6 sm:grid-cols-4 grid-cols-3 md:gap-8 gap-4">
                                    {data.filter(item => JSON.parse(item.input_fields).is_quantity_selection_allowed === 'YES').map((item) => (
                                        <div className="single-game-product mb-2 md:mb-6" key={item.id}>
                                            <a href={`/topup/${item.code}`} className="triangle">
                                                <div className="cursor-pointer">
                                                    <div className="inset-0 opacity-25"></div>
                                                    <div className="inset-0 transform hover:scale-90 transition duration-300">
                                                        <div className="h-full w-full text-center mx-auto">
                                                            <img
                                                                src={`${config.baseURL}storage/products/${item.gallery}`}
                                                                width="200"
                                                                height="100"
                                                                alt="Product Img"
                                                                data-nuxt-img=""
                                                                sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 400px"
                                                                srcSet={`
                                                ${config.baseURL}storage/products/${item.gallery} 1w,
                                                ${config.baseURL}storage/products/${item.gallery} 2w,
                                                ${config.baseURL}storage/products/${item.gallery} 320w,
                                                ${config.baseURL}storage/products/${item.gallery} 400w,
                                                ${config.baseURL}storage/products/${item.gallery} 640w,
                                                ${config.baseURL}storage/products/${item.gallery} 800w
                                            `}
                                                                className="rounded-md"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h1 className="capitalize text-xs text-center pt-3 font-primary font-extralight text-secondary-500">{item.name}</h1>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Pagination */}
                            <div className="flex items-center justify-center px-4 mt-0 md:mt-2 section-contact-gap py-2 pb-4 md:py-8">
                                <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />

                                <button
                                    className="text-2xl sm:text-3xl text-center font-primary font-bold mx-4 text-secondary-900"
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => setCurrentPage(prevPage => prevPage > 1 ? prevPage - 1 : 1)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-arrow-left"
                                        viewBox="0 0 16 16"
                                        style={{ marginRight: '4px' }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.854 8.354a.5.5 0 0 0 0 .707L8.793 12.5a.5.5 0 0 0 .707-.707L7.207 8l2.293-2.293a.5.5 0 0 0-.707-.707L5.147 7.646a.5.5 0 0 0 0 .708l3.293 3.293a.5.5 0 0 0 .708-.708L5.854 8.354z"
                                        />
                                    </svg>
                                    Previous
                                </button>
                                <button
                                    className="text-2xl sm:text-3xl text-center font-primary font-bold mx-4 text-secondary-900"
                                    style={{ marginLeft: '4px', display: 'flex', alignItems: 'center' }}
                                    onClick={() => setCurrentPage(prevPage => prevPage + 1)}
                                >
                                    Next
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-arrow-right"
                                        viewBox="0 0 16 16"
                                        style={{ marginLeft: '4px' }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10.646 8.354a.5.5 0 0 0 0-.707L7.707 4.5a.5.5 0 0 0-.707.707L9.293 8 7.707 9.586a.5.5 0 0 0 .707.707l2.293-2.293a.5.5 0 0 0 0-.708l-3.293-3.293a.5.5 0 0 0-.708.708L10.646 8.354z"
                                        />
                                    </svg>
                                </button>
                                <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                            </div>
                        </div>
                    </section>

                </div>
            </div>

        </>
    );
}
