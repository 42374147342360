import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import config from './../config';

export default function PaymentCancel() {
    const [userId, setUserId] = useState(null); // State to hold user ID
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    useEffect(() => {
        const fetchUserData = () => {
            try {
                const user = JSON.parse(localStorage.getItem('user-info'));
                if (user && user.id) {
                    setUserId(user.id);
                    updateOrderStatus(user.id); // Call function to update order status
                } else {
                    console.error('User info not found in localStorage');
                }
            } catch (error) {
                console.error('Error parsing user info from localStorage:', error);
            }
        };

        fetchUserData();
    }, []);

    const updateOrderStatus = async (userId) => {
        try {
            const response = await fetch(`${config.baseURL}/api/cancel-payment/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userId,
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log('Order status updated successfully');
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyle = isMobile
        ? { marginTop: '17%' }
        : { marginTop: '-13%' }; // Adjust marginTop value as needed


    return (
        <body className="w-full min-h-screen sm:h-auto sm:p-12 sm:flex sm:items-center sm:justify-center" style={containerStyle}>
            <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"', backgroundColor: '#ffffff', color: '#718096', lineHeight: '1.4', margin: 0, padding: 0 }}>
                <table width="100%" cellSpacing="0" cellPadding="0" role="presentation" style={{ backgroundColor: '#edf2f7', margin: 0, padding: 0, width: '100%' }}>
                    <tbody>
                        <tr>
                            <td align="center" style={{ backgroundColor: '#edf2f7', margin: 0, padding: 0 }}>
                                <table width="100%" cellSpacing="0" cellPadding="0" role="presentation" style={{ margin: 0, padding: 0, maxWidth: '600px', width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ backgroundColor: '#ffffff', border: '1px solid #e8e5ef', borderRadius: '2px', margin: '0 auto', padding: '32px' }}>
                                                <p className="text text-danger">Payment Cancelled.</p>
                                                <h3 style={{ color: 'red' }}>Payment Cancelled</h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </body>
    );
}
