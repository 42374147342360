// src/PaymentPage.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './PaymentPage.css'; // Assume you have a corresponding CSS file
import logo from './../assets/img/1711652676.png'; // Adjust the path as needed
import bkash from './../assets/img/bkash.png'; // Adjust the path as needed
import copy from './../assets/img/copy.png'; // Adjust the path as needed


export default function BkashCheckout() {
    const navigate = useNavigate();
    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = !!localStorage.getItem('user-info'); // Assuming you store user info in localStorage
        if (!isLoggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);
    const productDetails = JSON.parse(localStorage.getItem('productDetails'));
    const totalAmount = productDetails ? productDetails.totalAmount : 0;
    const accountInfo = productDetails ? productDetails.accountInfo : {};

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyle = isMobile
        ? { marginTop: '5%' } // Adjust marginBottom for mobile
        : { marginTop: '4%' }; // Adjust marginBottom for desktop15


    return (
        <>
            <body className="w-full min-h-screen sm:h-auto sm:p-12 sm:flex sm:items-center sm:justify-center">
                {/* Overlay Start*/}
                <div id="page-overlay" className="visible incoming">
                    <div className="loader-wrapper-outer">
                        <div className="loader-wrapper-inner">
                            <div className="lds-double-ring">
                                <div></div>
                                <div></div>
                                <div>
                                    <div></div>
                                </div>
                                <div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Overlay End */}
                {/* Full Design */}
                <div className="up-container max-w-md overflow-hidden mx-auto p-8 sm:relative sm:bg-white sm:rounded-lg sm:shadow-lg sm:shadow-[#0057d0]/10 sm:min-w-[650px] sm:flex sm:flex-wrap">
                    {/* nav */}
                    <div className="w-full h-12 shadow-md shadow-[#0057d0]/5 rounded-lg overflow-hidden flex justify-between items-center p-5 bg-white sm:bg-[#fbfcff]  sm:shadow-none sm:ring-1 sm:ring-[#0057d0]/10">
                        <div className="">
                            <a href="https://pay.srgameshop.com/payment/fd8f2196802593b75e2bb32a02fd286ec1e34cdc">
                                <svg width="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1Z" stroke="#6D7F9A" stroke-width="1.5"></path>
                                    <path d="M10.7749 12.9L7.3749 9.50002L10.7749 6.10002" stroke="#94A9C7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </a>
                        </div>
                        <div className="flex items-center">
                            <a href="#" className="mr-5" id="language" data-language="bangla">
                                <svg width="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.39719 7.97288L4.88063 9.5H3.5625L5.77362 3.5625H7.29837L9.5 9.5H8.11419L7.59762 7.97288H5.39719ZM7.33756 7.09888L6.53125 4.69775H6.47306L5.66675 7.09888H7.33875H7.33756Z" fill="#94A9C7"></path>
                                    <path d="M0 2.375C0 1.74511 0.250223 1.14102 0.695621 0.695621C1.14102 0.250223 1.74511 0 2.375 0L10.6875 0C11.3174 0 11.9215 0.250223 12.3669 0.695621C12.8123 1.14102 13.0625 1.74511 13.0625 2.375V5.9375H16.625C17.2549 5.9375 17.859 6.18772 18.3044 6.63312C18.7498 7.07852 19 7.68261 19 8.3125V16.625C19 17.2549 18.7498 17.859 18.3044 18.3044C17.859 18.7498 17.2549 19 16.625 19H8.3125C7.68261 19 7.07852 18.7498 6.63312 18.3044C6.18772 17.859 5.9375 17.2549 5.9375 16.625V13.0625H2.375C1.74511 13.0625 1.14102 12.8123 0.695621 12.3669C0.250223 11.9215 0 11.3174 0 10.6875V2.375ZM2.375 1.1875C2.06006 1.1875 1.75801 1.31261 1.53531 1.53531C1.31261 1.75801 1.1875 2.06006 1.1875 2.375V10.6875C1.1875 11.0024 1.31261 11.3045 1.53531 11.5272C1.75801 11.7499 2.06006 11.875 2.375 11.875H10.6875C11.0024 11.875 11.3045 11.7499 11.5272 11.5272C11.7499 11.3045 11.875 11.0024 11.875 10.6875V2.375C11.875 2.06006 11.7499 1.75801 11.5272 1.53531C11.3045 1.31261 11.0024 1.1875 10.6875 1.1875H2.375ZM10.8514 13.0566C11.0806 13.414 11.3287 13.7489 11.5995 14.0612C10.7112 14.744 9.61281 15.2499 8.3125 15.5954C8.52388 15.8531 8.84806 16.3495 8.97156 16.625C10.3075 16.1987 11.4416 15.6227 12.3987 14.8509C13.3214 15.6406 14.4638 16.2343 15.8781 16.5989C16.036 16.2972 16.3697 15.7997 16.625 15.542C15.2891 15.2416 14.1823 14.7179 13.2763 14.0173C14.0849 13.1302 14.7274 12.0567 15.2012 10.7433H16.625V9.5H13.0625V10.7433H13.9709C13.5933 11.7456 13.0922 12.5792 12.4604 13.2727C12.2859 13.0868 12.1214 12.8918 11.9676 12.6884C11.6325 12.9033 11.2485 13.0299 10.8514 13.0566Z" fill="#6D7F9A"></path>
                                </svg>
                            </a>
                            <form action="https://pay.srgameshop.com/checkout/cancel" method="post" accept-charset="utf-8">
                                <input type="hidden" name="invoice_id" value="bMamBhkHgis8zia0dNWM" />
                                <input type="hidden" name="csrf_rt_66b314f7e4_token" value="a108a36c82c78968d6212208ff42d3fd" />
                                <button type="submit">
                                    <svg width="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13 1L1 13" stroke="#94A9C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M1 1L13 13" stroke="#6D7F9A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </button>
                            </form>
                        </div>
                    </div>
                    {/*END nav */}
                    {/* bKash Transaction */}
                    <div className="w-full">
                        <div className="flex flex-col sm:flex-row flex-wrap sm:mt-5 sm:justify-between sm:items-center">
                            <div className="w-full h-20 mb-4 sm:mt-0 flex justify-center items-center">
                                <img src={bkash} alt="Logo" className="h-[80%]" />
                            </div>
                            <div className="bg-white shadow shadow-[#0057d0]/5 rounded-lg px-5 py-3 sm:h-[85px] flex items-center sm:w-[70%] sm:shadow-none sm:ring-1 sm:ring-[#0057d0]/10">
                                <div className="w-[55px] h-[55px] p-1.5 flex justify-center items-center mr-4 ring-1 ring-[#0057d0]/10 rounded-full">
                                    <img src={logo} alt="Logo" className="w-[80%]" />
                                </div>
                                <div className="flex flex-col">
                                    <h3 className="font-semibold text-[#6D7F9A]">CODVOUCHERS</h3>
                                    <span className="text-[#94a9c7] text-sm font-bangla">ইনভয়েস আইডিঃ</span>
                                    <p className="text-[#6D7F9A] text-sm select-all">bMamBhkHgis8zia0dNWM</p>
                                </div>
                            </div>
                            <div className="bg-white shadow shadow-[#0057d0]/5 rounded-lg py-3 px-2 sm:h-[85px] flex flex-col sm:items-center sm:justify-center sm:shadow-none sm:ring-1 sm:ring-[#0057d0]/10 mt-3 sm:w-[25%] sm:mt-0">
                                <h1 className="text-xl sm:text-2xl font-semibold text-[#6D7F9A]">৳ {totalAmount}</h1>
                                <span className="text-xs text-red-600 font-medium mt-1 text-left sm:text-center font-bangla">1.15% ফি যুক্ত হয়েছে</span>
                            </div>
                        </div>
                        <form action="https://pay.srgameshop.com/checkout/verify-payment-data" className="actionForm" method="post" accept-charset="utf-8">
                            <input type="hidden" name="payment_id" value="fd8f2196802593b75e2bb32a02fd286ec1e34cdc" />
                            <input type="hidden" name="payment_method" value="bkash" />
                            <input type="hidden" name="payment_method_id" value="1" />
                            <input type="hidden" name="csrf_rt_66b314f7e4_token" value="a108a36c82c78968d6212208ff42d3fd" />
                            {/* Transaction Id */}
                            <div className="brand-bg p-5 mt-3 rounded-lg overflow-auto">
                                <div className="text-center mt-3">
                                    <h2 className="mb-3 font-semibold text-white font-bangla">ট্রান্সজেকশন আইডি দিন</h2>
                                    <input type="text" name="transaction_id" placeholder="ট্রান্সজেকশন আইডি দিন" className="font-bangla appearance-none w-full text-[15px] rounded-[10px] sm:bg-[#fbfcff] shadow shadow-[#0057d0]/5 px-5 py-3.5 placeholder-[#94A9C7] focus:outline-none focus:ring-1 focus:ring-[#0057d0]" maxlength="10" required="" />
                                </div>
                                {/* Phone Number */}
                                <div id="sender_number" className="text-center mt-3 hidden">
                                    <h2 className="mb-3 font-semibold text-white font-bangla">ফোন নম্বর লিখুন</h2>
                                    <input type="text" name="phone_number" placeholder="ফোন নম্বর লিখুন" className="font-bangla appearance-none w-full text-[15px] rounded-[10px] sm:bg-[#fbfcff] shadow shadow-[#0057d0]/5 px-5 py-3.5 placeholder-[#94A9C7] focus:outline-none focus:ring-1 focus:ring-[#0057d0]" maxlength="12" />
                                </div>
                                {/* Phone Number */}
                                <div className="">
                                    <ul className="mt-5 text-slate-200">
                                        <li className="flex text-sm">
                                            <div><span className="inline-block w-1.5 h-1.5 mr-2 bg-white rounded-full mb-0.5"></span>
                                            </div>
                                            <p className="font-bangla">*247# ডায়াল করে আপনার BKASH মোবাইল মেনুতে যান অথবা BKASH অ্যাপে যান।</p>
                                        </li>
                                        <hr className="brand-hr my-3" />
                                        <li className="flex text-sm">
                                            <div><span className="inline-block w-1.5 h-1.5 mr-2 bg-white rounded-full mb-0.5"></span>
                                            </div>
                                            <p className="font-bangla"><span className="text-yellow-300 font-semibold ml-1">
                                                "Send Money"
                                            </span> -এ ক্লিক করুন।
                                            </p>
                                        </li>
                                        <hr className="brand-hr my-3" />
                                        <li className="flex text-sm">
                                            <div><span className="inline-block w-1.5 h-1.5 mr-2 bg-white rounded-full mb-0.5"></span>
                                            </div>
                                            <p className="sm:w-[90%] font-bangla">প্রাপক নম্বর হিসেবে এই নম্বরটি লিখুনঃ                                    <span className="text-yellow-300 font-semibold ml-1"> 01738836617</span>
                                                <a href="#" className="px-2 py-0.5 mx-2 rounded-md inline-block bg-[#00000040] copy" data-clipboard-text="01738836617"><img src={copy} className="w-3 inline mr-2" alt="Copy" />Copy</a>
                                            </p>
                                        </li>
                                        <hr className="brand-hr my-3" />
                                        <li className="flex text-sm">
                                            <div><span className="inline-block w-1.5 h-1.5 mr-2 bg-white rounded-full mb-0.5"></span>
                                            </div>
                                            <p className="font-bangla">টাকার পরিমাণঃ <span className="text-yellow-300 font-semibold ml-1"> {totalAmount}</span></p>
                                        </li>
                                        <hr className="brand-hr my-3" />
                                        <li className="flex text-sm">
                                            <div><span className="inline-block w-1.5 h-1.5 mr-2 bg-white rounded-full mb-0.5"></span>
                                            </div>
                                            <p className="font-bangla">নিশ্চিত করতে এখন আপনার BKASH মোবাইল মেনু পিন লিখুন।</p>
                                        </li>
                                        <hr className="brand-hr my-3" />
                                        <li className="flex text-sm">
                                            <div><span className="inline-block w-1.5 h-1.5 mr-2 bg-white rounded-full mb-0.5"></span>
                                            </div>
                                            <p className="font-bangla">সবকিছু ঠিক থাকলে, আপনি BKASH থেকে একটি নিশ্চিতকরণ বার্তা পাবেন।</p>
                                        </li>
                                        <hr className="brand-hr my-3" />
                                        <li className="flex text-sm">
                                            <div><span className="inline-block w-1.5 h-1.5 mr-2 bg-white rounded-full mb-0.5"></span>
                                            </div>
                                            <p className="font-bangla">এখন উপরের বক্সে আপনার<span className="text-yellow-300 font-semibold ml-1">Transaction ID</span> দিন এবং নিচের<span className="text-yellow-300 font-semibold ml-1">VERIFY</span> বাটনে ক্লিক করুন।</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* END Transaction Id */}
                            <div className="mt-5">
                                <button type="submit" id="form_submit" className="brand-btn block rounded-[10px] px-4 py-3.5 text-center font-semibold text-white transition-colors w-full">VERIFY</button>
                            </div>
                        </form>
                    </div>
                    {/*END bKash Transaction */}
                </div>


            </body>
        </>
    );
}

