import React, { createContext, useState, useEffect, useContext } from 'react';
import config from './../config';

// Create a Context for the site settings
const SiteSettingsContext = createContext();

export function SiteSettingsProvider({ children }) {
    const [siteSettings, setSiteSettings] = useState(null);

    useEffect(() => {
        // Fetch site settings from the API
        async function fetchSiteSettings() {
            try {
                const response = await fetch(`${config.baseURL}api/frontend-settings`);
                const data = await response.json();
                setSiteSettings(data.site_settings);
            } catch (error) {
                console.error('Failed to fetch site settings:', error);
            }
        }

        fetchSiteSettings();
    }, []);

    return (
        <SiteSettingsContext.Provider value={siteSettings}>
            {children}
        </SiteSettingsContext.Provider>
    );
}

export function useSiteSettings() {
    return useContext(SiteSettingsContext);
}
