import React, { useState, useEffect } from 'react';
import config from './../config';

const HomePageModal = ({ onClose }) => {
    const [modalData, setModalData] = useState(null);

    useEffect(() => {
        const fetchModalData = async () => {
            try {
                const response = await fetch(`${config.baseURL}api/frontend-settings`);
                const data = await response.json();
                const noticePopup = data.site_settings.notice_popup;

                // Check if status is not '0'
                if (noticePopup.status !== '0') {
                    setModalData(noticePopup);
                } else {
                    setModalData(null); // Ensure no data is set if status is '0'
                }
            } catch (error) {
                console.error('Error fetching modal data:', error);
            }
        };

        fetchModalData();
    }, []);

    if (!modalData) return null; // Hide the modal if there's no data

    return (
        <div className="inset-0 h-screen flex p-1" style={{ background: 'rgba(24, 24, 24, 0.62)', overflow: 'hidden', position: 'fixed', zIndex: 999999999 }}>
            <div className="relative bg-white m-auto rounded align-middle shadow-lg transition-opacity transition-transform duration-300" style={{ maxWidth: '600px', marginTop: '165px' }}>
                <button
                    style={{ position: 'absolute', right: '0px', top: '-33px', backgroundColor: 'white', borderRadius: '50%', width: '30px', height: '30px' }}
                    onClick={onClose}
                >
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className="w-full h-full" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                        <path d="M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path>
                    </svg>
                </button>
                <div className="md:flex">
                    <div className="w-full">
                        <img
                            src={`${config.baseURL}storage/popup_images/${modalData.image}`}
                            alt="Notice Image"
                            className="home_page_notice_img"
                            style={{ height: '100%', width: '100%', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
                        />
                    </div>
                    <div className="w-full text-xs p-3 home_page_notice_message" style={{ width: '100%' }}>

                        <span className="text-base">
                            <p> {modalData.message ? (
                                <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: modalData.message }} />
                            ) : (
                                'No message available'
                            )}


                            </p>
                        </span>
                        <div className="text-center mt-3">
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 rounded"
                                href={modalData.external_link}
                                style={{ fontSize: '16px' }}
                            >
                                Click Here
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePageModal;
