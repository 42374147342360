import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { useSiteSettings } from './SiteSettingsContext';

export default function ProfileAddMoney() {
    const user = JSON.parse(localStorage.getItem('user-info'));


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [amount, setAmount] = useState('');
    const [isValidAmount, setIsValidAmount] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = !!localStorage.getItem('user-info'); // Assuming you store user info in localStorage
        if (!isLoggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);
    const handlePayment = async (paymentData) => {
        try {
            const response = await fetch(`${config.baseURL}api/payment/initiate-wallet-topup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(paymentData),
            });

            const data = await response.json();

            if (response.ok && data.payment_url) {
                window.location.href = data.payment_url;
            } else {
                setError(data.message || 'Payment initiation failed');
            }
        } catch (error) {
            setError('Payment initiation failed: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleBuyNow = () => {
        setLoading(true);
        const productDetails = {
            //for payment gateway
            full_name: user.name,
            email: user.email,
            amount: amount,
            user_id: user.id,
        };
        // Log all parameters and values for confirmation
        console.log('Product Details:', productDetails);

        // Store product details and selected payment method in localStorage or state management solution
        localStorage.setItem('productDetails', JSON.stringify(productDetails));
        handlePayment(productDetails);
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleAmountChange = (e) => {
        const value = e.target.value;
        setAmount(value);
        if (/^\d+(\.\d{1,2})?$/.test(value)) {
            setIsValidAmount(true);
            setError('');
        } else {
            setIsValidAmount(false);
            setError('Please enter a valid amount');
        }
    };

    const containerStyle = isMobile
        ? { marginTop: '15%' } // Adjust marginTop for mobile
        : { marginTop: '6%' }; // Adjust marginTop for desktop
    const siteSettings = useSiteSettings();
    if (!siteSettings) {
        return <div>Loading...</div>;
    }

    const { contact_info } = siteSettings;
    return (
        <div className="container m-auto" style={containerStyle}>
            <div className="mx-auto min-w-fit my-10 overflow-hidden" style={{ maxWidth: '700px', overflow: 'hidden' }}>
                <div className="rounded flex-no-shrink bg-white">
                    <div className="text-left px-3 flex items-center">
                        <h2 className="text-lg text-black py-2 font-normal fb">Add Money</h2>
                    </div>
                    <hr />
                    <div className="p-2 md:p-3">
                        <label className="">
                            <div className="flex content-center items-center justify-between text-sm">
                                <p className="block font-medium text-gray-700 dark:text-gray-200 font-primary">Enter the amount</p>
                            </div>
                            <div className="mt-1 relative">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Amount"
                                        value={amount}
                                        onChange={handleAmountChange}
                                        className="form-input relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-3.5 py-2.5 shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400"
                                    />
                                </div>
                            </div>
                            {error && <p className="text-red-500">{error}</p>}
                        </label>
                        <div className="text-center">
                            <button
                                type="button"
                                onClick={handleBuyNow}
                                disabled={!isValidAmount || loading}
                                className="justify-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 px-2.5 py-2.5 shadow-sm text-white dark:text-gray-900 bg-primary-500 hover:bg-primary-600 disabled:bg-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:disabled:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 dark:focus-visible:outline-primary-400 inline-flex items-center my-2 w-full text-center"
                            >
                                {loading ? (
                                    <div className="flex-center">
                                        <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2" />
                                        Loading
                                    </div>
                                ) : (
                                    " Click Here To Add Money"
                                )}

                            </button>
                        </div>
                    </div>
                </div>



                <div className="rounded bg-white my-4 md:my-10" style={{ borderRadius: '0.375rem', borderWidth: '1px' }}>
                    <div className="text-left px-3 flex items-center">
                        <svg viewBox="0 0 24 24" className="text-green-100 mr-2" style={{ width: '22px', height: '22px' }}>
                            <path
                                fill="currentColor"
                                d="M3 4V16H21V4H3M3 2H21C22.1 2 23 2.89 23 4V16C23 16.53 22.79 17.04 22.41 17.41C22.04 17.79 21.53 18 21 18H14V20H16V22H8V20H10V18H3C2.47 18 1.96 17.79 1.59 17.41C1.21 17.04 1 16.53 1 16V4C1 2.89 1.89 2 3 2M10.84 8.93C11.15 8.63 11.57 8.45 12 8.45C12.43 8.46 12.85 8.63 13.16 8.94C13.46 9.24 13.64 9.66 13.64 10.09C13.64 10.53 13.46 10.94 13.16 11.25C12.85 11.56 12.43 11.73 12 11.73C11.57 11.73 11.15 11.55 10.84 11.25C10.54 10.94 10.36 10.53 10.36 10.09C10.36 9.66 10.54 9.24 10.84 8.93M10.07 12C10.58 12.53 11.28 12.82 12 12.82C12.72 12.82 13.42 12.53 13.93 12C14.44 11.5 14.73 10.81 14.73 10.09C14.73 9.37 14.44 8.67 13.93 8.16C13.42 7.65 12.72 7.36 12 7.36C11.28 7.36 10.58 7.65 10.07 8.16C9.56 8.67 9.27 9.37 9.27 10.09C9.27 10.81 9.56 11.5 10.07 12M6 10.09C6.94 7.7 9.27 6 12 6C14.73 6 17.06 7.7 18 10.09C17.06 12.5 14.73 14.18 12 14.18C9.27 14.18 6.94 12.5 6 10.09Z"
                            ></path>
                        </svg>
                        <h2 className="text-lg text-black py-2 font-normal fb">How to add money</h2>
                    </div>
                    <hr />
                    <div className="flex-no-shrink p-2 mr-3 flex md:flex-row items-center flex-wrap justify-center sm:flex-col">
                        <div className="w-full">
                            <iframe
                                title="Add Money Tutorial"
                                src={contact_info.how_to_add_money}
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                style={{ width: '100%', height: 'auto', minHeight: '250px', minWidth: '340px' }}
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
