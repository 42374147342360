import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from './../config';


export default function Search({ searchData }) {
    // Log the incoming searchData to debug
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyle = isMobile
        ? { marginTop: '5%' } // Adjust marginBottom for mobile
        : { marginBottom: '17%' }; // Adjust marginBottom for desktop15
    return (
        <div className="secondary-section body-content" style={containerStyle}>

            <section className="md:my-10 my-3">
                <div className="container mx-auto">
                    <div className="text-center">
                        <div className="flex items-center justify-center px-4 mt-0 md:mt-2 section-contact-gap py-2 pb-4 md:py-8">
                            <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                            <h3 className="text-2xl sm:text-3xl text-center font-primary font-bold mx-4 text-secondary-900">Item(s) Found Against Your Search</h3>
                            <hr role="separator" aria-orientation="horizontal" className="section-divider v-divider theme--light" />
                        </div>
                    </div>
                    <div className="pb-1 md:pb-10">
                        <div className="md:py-5 md:px-0 grid md:grid-cols-6 sm:grid-cols-4 grid-cols-3 md:gap-8 gap-4">

                            {searchData && searchData.length > 0 ? (
                                searchData.map((item) => (
                                    <div className="single-game-product mb-2 md:mb-6" key={item.id}>
                                        <Link to={`/topup/${item.code}`} className="triangle">
                                            <div className="cursor-pointer">
                                                <div className="inset-0 opacity-25"></div>
                                                <div className="inset-0 transform hover:scale-90 transition duration-300">
                                                    <div className="h-full w-full text-center mx-auto">
                                                        <img
                                                            src={`${config.baseURL}storage/products/${item.gallery}`}
                                                            width="200"
                                                            height="100"
                                                            alt="Product Img"
                                                            data-nuxt-img=""
                                                            sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 400px"
                                                            srcSet={`
                                                ${config.baseURL}storage/products/${item.gallery} 1w,
                                                ${config.baseURL}storage/products/${item.gallery} 2w,
                                                ${config.baseURL}storage/products/${item.gallery} 320w,
                                                ${config.baseURL}storage/products/${item.gallery} 400w,
                                                ${config.baseURL}storage/products/${item.gallery} 640w,
                                                ${config.baseURL}storage/products/${item.gallery} 800w
                                            `}
                                                            className="rounded-md"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <h1 className="capitalize text-xs text-center pt-3 font-primary font-extralight text-secondary-500">{item.name}</h1>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            ) : (
                                <div className="text-danger fs-4 text-center">Sorry, No result found!!</div>
                            )}

                        </div>

                    </div>
                </div>
            </section >
        </div>

    );
}
