import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import config from './../config';

export default function MyCodes() {
    const user = JSON.parse(localStorage.getItem('user-info'));
    const [copySuccess, setCopySuccess] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [orders, setOrders] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [copyTimer, setCopyTimer] = useState(null); // Timer for countdown animation
    const navigate = useNavigate();
    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = !!localStorage.getItem('user-info'); // Assuming you store user info in localStorage
        if (!isLoggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);
    const userId = user.id; // replace this with the actual user ID

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        fetchOrders(currentPage);
    }, [currentPage]);

    const fetchOrders = (page) => {
        setLoading(true);
        fetch(`${config.baseURL}api/user-codes/${userId}?page=${page}`)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.message || 'Network response was not ok');
                    });
                }
                return response.json();
            })
            .then(data => {
                if (data.data.length === 0) {
                    setError('No codes found for this user.');
                } else {
                    setOrders(data.data);
                    setTotalPages(data.last_page);
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error.message || 'Error fetching codes');
                setLoading(false);
            });
    };

    const handleCopyCode = (licenseKey) => {
        navigator.clipboard.writeText(licenseKey);
        setCopySuccess(true);
        setCopyTimer(setTimeout(() => {
            setCopySuccess(false);
            setCopyTimer(null);
        }, 1000)); // Flash duration: 1 second
    };

    const containerStyle = isMobile
        ? { marginTop: '15%' }
        : { marginTop: '10%' };
    const copyStyle = isMobile
        ? { marginTop: '75%' }
        : { marginTop: '30%' };

    return (
        <>
            <div className="container m-auto" style={containerStyle}>
                <div className="md:px-0 min-h-screen text-black p-2">
                    <div className="bg-white border rounded-lg overflow-hidden">
                        <div className="text-left px-3 flex items-center justify-between">
                            <div className="flex items-center">
                                <svg className="mr-2" viewBox="0 0 24 24" style={{ width: '24px', height: '24px' }}>
                                    <path fill="currentColor" d="M11 15H17V17H11V15M9 7H7V9H9V7M11 13H17V11H11V13M11 9H17V7H11V9M9 11H7V13H9V11M21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H19C20.1 3 21 3.9 21 5M19 5H5V19H19V5M9 15H7V17H9V15Z"></path>
                                </svg>
                                <h2 className="text-lg text-black py-2 font-normal fb">My Codes</h2>
                            </div>
                            <a href="https://shop.garena.my/app" target="_blank" rel="noopener noreferrer" className="bg-pink-500 p-2 text-white rounded-md" style={{ maxWidth: '125px', maxHeight: '50px' }}>
                                Redeem Code
                            </a>
                        </div>
                        <hr />
                        <div className="box-form w-full text-center">
                            {loading ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>{error}</p>
                            ) : Object.keys(orders).length > 0 ? (
                                Object.keys(orders).map(key => {
                                    const order = orders[key];
                                    const dataFields = JSON.parse(order.data);
                                    return (
                                        <div key={order.id} className="border-b-2 m-2">
                                            <div className="sm:flex">
                                                <div className="w-full sm:w-1/2">
                                                    <p className="px-3 py-1 text-left"><span className="font-bold">TRX ID: </span> {order.trx_id}</p>
                                                    <p className="px-3 py-1 text-left"><span className="font-bold">Date: </span> {new Date(order.created_at).toLocaleString()}</p>
                                                    <p className="px-3 py-1 text-left"><span className="font-bold">Package: </span> {dataFields.selectedRechargeType}</p>
                                                </div>
                                                <div className="w-full sm:w-1/2">
                                                    {Object.entries(dataFields.accountInfo).map(([key, value]) => (
                                                        <p className="px-3 py-1 text-left" key={key}>
                                                            <span className="font-bold">{key.replace('_', ' ')}: </span> {value}
                                                        </p>
                                                    ))}
                                                    <p className="px-3 py-1 text-left"><span className="font-bold">Price: </span> ৳ {order.amount_paid}</p>

                                                    <p className="px-3 py-1 text-left">
                                                        <span className="font-bold">Status: </span>
                                                        <span className={`font-bold ${order.status === 'COMPLETED' ? 'text-green-500' : 'text-red-500'}`}>
                                                            {order.status}
                                                        </span>
                                                        {order.status === 'COMPLETED' && (
                                                            <span className="text-sm"> ( {new Date(order.updated_at).toLocaleString()} ) </span>
                                                        )}
                                                        {order.status === 'Rejected' && order.reject_reason && (
                                                            <p className="text-sm text-red-600 mt-2">
                                                                <span className="font-bold">Reason: </span>
                                                                {order.reject_reason}
                                                            </p>
                                                        )}
                                                    </p>

                                                    <div className="w-full">
                                                        {order.status === 'COMPLETED' ? (
                                                            <>
                                                                {order.license_key ? (
                                                                    <>
                                                                        <p className="px-3 py-1 text-left">
                                                                            <span className="font-bold">Your Code: </span>
                                                                        </p>

                                                                        <div
                                                                            style={{
                                                                                background: 'rgb(241, 236, 247)',
                                                                                margin: '0px 12px',
                                                                                padding: '5px 4px',
                                                                                borderRadius: '5px',
                                                                                whiteSpace: 'pre-line',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {order.license_key}
                                                                        </div>

                                                                        <button
                                                                            id={`copy-number-${order.id}`}
                                                                            onClick={() => handleCopyCode(order.license_key)}
                                                                            className="align-middle px-2 py-1 text-sm font-thin rounded w-38 flex items-center text-center ml-3 mt-2"
                                                                            style={{
                                                                                background: 'rgb(224, 224, 224)',
                                                                                border: '2px solid rgb(109, 109, 109)',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="w-38 rounded h-full flex items-center"
                                                                                style={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    borderRadius: '50%',
                                                                                    color: 'rgb(40, 70, 153)',
                                                                                    fontSize: '20px',
                                                                                    fontWeight: '900',
                                                                                    marginRight: '6px',
                                                                                }}
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <title>content-copy</title>
                                                                                    <path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"></path>
                                                                                </svg>
                                                                            </div>
                                                                            Copy Code
                                                                        </button>

                                                                        {copySuccess && (
                                                                            <div
                                                                                className="fixed bottom-4 sm:bottom-0 right-0 w-full sm:w-96 pointer-events-none"
                                                                                style={copyStyle}
                                                                            >
                                                                                {/* Adjusted positioning to bottom-right */}
                                                                                <div
                                                                                    className="relative px-4 sm:px-6 py-3 sm:py-6 space-y-3 overflow-y-auto bg-white border-t-4 border-red-500 shadow-lg"
                                                                                >
                                                                                    {/* Decreased padding on smaller screens and adjusted height */}
                                                                                    <p className="text-center">Copied Successfully</p>
                                                                                    <div className="h-1 relative w-full bg-red-500">
                                                                                        <div
                                                                                            className="absolute h-full top-0 left-0 bg-red-500"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                transition: 'width 1s linear',
                                                                                                animation: `${copyTimer ? 'progress-animation' : ''} 1s linear`,
                                                                                            }}
                                                                                        ></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <p className="px-3 py-1 text-left">
                                                                        <span className="font-bold">Your Code: Not Available</span>
                                                                    </p>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className="px-3 py-1 text-left">
                                                                    <span className="font-bold">Your Code: PENDING</span>
                                                                </p>
                                                            </>
                                                        )}
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <>
                                    <h4 className="fb-normal">No order data found!</h4>
                                    <Link
                                        to="/topup"
                                        className="bg-red-500 border border-red-500 hover:bg-red-500 text-white text-xs py-1 px-2 md:px-2 rounded uppercase srgameshop-btn"
                                    >
                                        Order Now
                                    </Link>
                                </>
                            )}
                        </div>
                        <div className="flex text-gray-700 justify-end">
                            <button
                                className="h-10 w-10 mr-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer"
                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-left w-6 h-6"
                                >
                                    <polyline points="15 18 9 12 15 6"></polyline>
                                </svg>
                            </button>
                            <span className="inline-flex items-center font-medium rounded-md text-xs px-2 py-1 bg-primary-500 dark:bg-primary-400 text-white dark:text-gray-900">
                                {currentPage}
                            </span>
                            <button
                                className="h-10 w-10 ml-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer"
                                onClick={() => setCurrentPage(prev => (prev < totalPages ? prev + 1 : prev))}
                                disabled={currentPage === totalPages}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-right w-6 h-6"
                                >
                                    <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
