import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Logout() {

    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            localStorage.removeItem('user-info');
            navigate('/login');
        } else {
            navigate('/login');
        }
    }, [])

    return (
        <div></div>
    )
}
