import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import config from './../config';
import walletImage from './../assets/img/wallet.jpg'; // Adjust the path as needed
import PaymentImage from './../assets/img/auto_payments.f9dfa769.png'; // Adjust the path as needed

export default function TopUpDetails() {

    const [data, setData] = useState([]);
    const user = JSON.parse(localStorage.getItem('user-info'));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorRecharge, setErrorA] = useState(null);
    const [errorPlayerID, setErrorB] = useState(null);

    const navigate = useNavigate();
    const { code } = useParams();
    const [product, setProduct] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [quantity, setQuantity] = useState(1);
    const [selectedRecharge, setSelectedRecharge] = useState(null);
    const [selectedRechargeIndex, setSelectedRechargeIndex] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [accountInfo, setAccountInfo] = useState({});
    const [selectedRechargeType, setSelectedRechargeType] = useState('');
    const [wallet, setWallet] = useState(null);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${config.baseURL}api/topup-detail/${code}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                const data = await response.json();
                setProduct(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
            }
        }
        fetchData();
    }, [code]);

    //fetchWalletData should only execute if the user is logged in

    useEffect(() => {
        async function fetchWalletData() {
            try {
                const response = await fetch(`${config.baseURL}api/user-wallet/${user.id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                const walletData = await response.json();
                setWallet(walletData);
            } catch (error) {
                console.error('Error fetching wallet data:', error);
                setWallet(null); // Ensure wallet is set to null in case of error
            }
        }

        if (user && user.id) {
            fetchWalletData();
        }
    }, [user]); // Added user as a dependency



    useEffect(() => {
        if (selectedRechargeIndex !== null) {
            const selectedRecharge = inputFieldsRecharge[selectedRechargeIndex];
            const maxQuantity = parseInt(selectedRecharge.recharge_stock, 10);
            const calculatedAmount = selectedRecharge.currency_amount * quantity;
            if (quantity > maxQuantity) {
                setQuantity(maxQuantity);
                setTotalAmount(selectedRecharge.currency_amount * maxQuantity);
            } else {
                setTotalAmount(calculatedAmount);
            }
        } else {
            setTotalAmount(0);
        }
    }, [quantity, selectedRechargeIndex]);


    if (!product) {
        return <div>Loading...</div>;
    }
    const inputFields = JSON.parse(product.input_fields);
    const inputFieldsPlayerId = JSON.parse(product.input_fields).input_fields_player_id;
    const inputFieldsRecharge = JSON.parse(product.input_fields).input_fields_recharge;
    const productId = JSON.parse(product.id);
    const isQuantitySelectionAllowed = JSON.parse(product.input_fields).is_quantity_selection_allowed;


    const containerStyle = isMobile ? { marginTop: '15%' } : { marginTop: '7%' };

    const handleRechargeSelect = (field, index) => {
        setSelectedRechargeIndex(index);
        setSelectedRechargeType(field.type);
        setQuantity(1); // Reset quantity to 1 when a new recharge option is selected
        setTotalAmount(field.currency_amount); // Set totalAmount based on the selected recharge option's amount
    };

    const handleQuantityChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value > 0 && value <= selectedRecharge.recharge_stock) {
            setQuantity(value);
        }
    };

    const incrementQuantity = () => {
        if (selectedRechargeIndex !== null && quantity < inputFieldsRecharge[selectedRechargeIndex].recharge_stock) {
            setQuantity(quantity + 1);
        }
    };

    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handlePaymentMethodChange = (e) => {
        setSelectedPaymentMethod(e.target.value);
    };

    const handleAccountInfoChange = (e) => {
        const { name, value } = e.target;
        setAccountInfo(prevInfo => ({
            ...prevInfo,
            [name]: value
        }));
    };

    const handlePayment = async (paymentData) => {
        // Prepare data for payment initiation
        try {
            // Check if selectedRechargeType is not empty or undefined before initiating payment
            if (!selectedRechargeType) {
                setErrorA('Recharge type is required');
                return;
            }
            if (isQuantitySelectionAllowed === "NO") {
                //check for Account info for instant payment here
                if (!accountInfo || Object.keys(accountInfo).length === 0) {
                    setErrorB('Player Id is required');
                    return;
                }

            }


            // Initiate payment
            const response = await fetch(`${config.baseURL}api/payment/initiate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(paymentData),
            });

            const data = await response.json();

            if (data.payment_url) {
                window.location.href = data.payment_url;
            } else {
                setError(data.message || 'Payment initiation failed');
            }
        } catch (error) {
            setError('Payment initiation failed: ' + error.message);
        } finally {
            setLoading(false);
        }
    };


    const handleWalletPayment = async (productDetails) => {
        try {

            const response = await fetch(`${config.baseURL}api/payment/wallet`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(productDetails),
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Wallet payment failed', error);
        }
    };
    const handleBuyNow = async () => {
        setLoading(true);
        const productDetails = {
            ...product, // Include all fields from the fetched product data
            selectedRechargeType,
            selectedPaymentMethod,
            quantity,
            totalAmount,
            accountInfo, // Include account info in the product details
            productId,
            //for payment gateway
            full_name: user.name,
            email: user.email,
            amount: totalAmount,
            user_id: user.id,
        };

        // Log all parameters and values for confirmation
        console.log('Product Details:', productDetails);

        // Store product details and selected payment method in localStorage or state management solution
        localStorage.setItem('productDetails', JSON.stringify(productDetails));

        // Navigate based on selected payment method
        if (selectedPaymentMethod === 'instant') {
            // Call handlePayment to initiate the payment process and pass productDetails
            handlePayment(productDetails);
        } else if (selectedPaymentMethod === 'wallet') {
            try {
                // Check if selectedRechargeType is not empty or undefined before initiating payment
                if (!selectedRechargeType) {
                    setErrorA('Recharge type is required');
                    return;
                }
                if (isQuantitySelectionAllowed === "NO") {
                    //check for Account info for instant payment here
                    if (!accountInfo || Object.keys(accountInfo).length === 0) {
                        setErrorB('Player Id is required');
                        return;
                    }

                }
                if (wallet && wallet.balance >= totalAmount) {
                    // Deduct amount from wallet balance and proceed with the payment process
                    const updatedWallet = {
                        ...wallet,
                        balance: wallet.balance - totalAmount,
                    };
                    setWallet(updatedWallet);

                    // Call handleWalletPayment to process payment from the wallet
                    const data = await handleWalletPayment(productDetails);

                    // Log what's stored in 'data'
                    console.log('Response from handleWalletPayment:', data);
                    console.log('Response from handleWalletPayment:', data.status);
                    // Check the response data for success
                    if (data.status == 'success') {
                        // Log success message (optional)
                        console.log('Payment successful from wallet');
                        console.log(data.accountInfo);
                        if (!accountInfo || Object.keys(accountInfo).length === 0) {
                            navigate('/profile/order');
                        } else {
                            navigate('/profile/order');
                        }
                    } else {
                        console.error('Wallet payment failed', data);
                        // Handle failure case (optional)
                        navigate('/profile/add-money'); // Navigate to default page or handle as needed
                    }
                } else {
                    navigate('/profile/add-money'); // Navigate to add money page if wallet balance is insufficient
                }
            } catch (error) {
                console.error('Wallet payment failed', error);
                // Handle error case (optional)
                navigate('/profile/add-money'); // Navigate to default page or handle as needed
            } finally {
                setLoading(false);
            }
        } else {
            navigate('/'); // Navigate to default page or handle other cases as needed
        }
    };


    return (
        <>

            <div className="px-2 md:px-0 container m-auto my-3 md:my-5" style={containerStyle}>
                <div className="bg-white border rounded-md">
                    <div className="flex">
                        <div><img className="rounded-3xl p-2 w-24 h-24" src={`${config.baseURL}storage/products/${product.gallery}`} alt={product.name} /></div>
                        <div className="flex items-center">
                            <div>
                                <h2 className="text-lg capitalize">{product.name}</h2>
                                <div className="text-gray-400 text-sm text-left"><span>Game </span> / <span>Top up </span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-2">
                    <form className="md:flex gap-2">
                        <section className="w-full md:w-2/3 mt-2">
                            <div className="bg-white border rounded-md">
                                <div className="text-left p-2 flex items-center">
                                    <div className="_order_header_step_circle mr-2">1</div>
                                    <h2 className="text-lg text-black py-2 font-bold fb-normal"> Select Recharge </h2>
                                </div>
                                <hr />
                                <div className="p-1 md:p-4 inline-grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-1 package-item-outer w-full">
                                    {inputFieldsRecharge.map((field, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            className={`sm-device-package mb-0 w-full drop-shadow-2xl list-group-item flex content-between p-2 ${selectedRechargeIndex === index ? 'active-package' : ''}`}
                                            onClick={() => handleRechargeSelect(field, index)}
                                            disabled={field.recharge_stock === "0"}
                                            style={{
                                                fontSize: '11px',
                                                position: 'relative',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                height: '50px',
                                                background: selectedRechargeIndex === index ? 'rgb(255, 247, 242)' : '',
                                                border: selectedRechargeIndex === index ? '2px solid red' : '1px solid rgb(255, 100, 0)',
                                                outline: selectedRechargeIndex === index ? 'rgb(255, 100, 0) solid 1px' : '',
                                            }}
                                        >
                                            <div className="w-full flex flex-wrap">
                                                <div className="flex items-center">
                                                    {selectedRechargeIndex === index ? (
                                                        <svg
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fas"
                                                            data-icon="check-circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            className="svg-inline--fa h-2 mr-2 w-4 fa-check-circle fa-w-16 fa-2x"
                                                            style={{ color: 'rgb(255, 100, 0)' }}
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fas"
                                                            data-icon="times-circle"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            className="svg-inline--fa h-2 mr-2 w-4 fa-times-circle fa-w-16 fa-2x"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                            />
                                                        </svg>
                                                    )}
                                                    <span className="text-xs font-primary">{field.type}</span>
                                                    {field.recharge_stock === "0" && (
                                                        <div>
                                                            <h6 className="bg-red-500 ml-2 rounded-full text-white px-2" style={{ fontSize: '8px', paddingTop: '3px', paddingBottom: '0px', maxWidth: '70px' }}>
                                                                STOCK OUT
                                                            </h6>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="font-bold fb-normal" style={{ color: 'rgb(255, 100, 0)', minWidth: '46px', float: 'right', textAlign: 'right' }}>
                                                {field.currency_amount} TK
                                            </div>
                                        </button>
                                    ))}


                                </div>


                                <div className="ml-4 mt-2 md:mt-0">
                                    <div>
                                        <p className="_body2 mb-3">
                                            <a target="_blank" rel="noreferrer" href="#" className="flex gap-2">
                                                <span className="text-lg flex" style={{ fontFamily: 'initial' }}>
                                                    {errorRecharge && <p className="text-red-500">{errorRecharge}</p>}
                                                </span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </section>
                        <div className="w-full md:w-1/3 mt-2">

                            {inputFields.is_quantity_selection_allowed === 'YES' ? (<section>
                                <div className="flex justify-between align-middle px-3 bg-white rounded-md border">
                                    <div className="my-auto font-primary"> Quantity </div>
                                    <div>
                                        <label htmlFor="Quantity" className="sr-only"> Quantity </label>
                                        <div className="flex items-center border-2 my-2 border-gray-200 rounded-full px-2">
                                            <div className="cursor-pointer w-6 h-6 flex items-center justify-center" onClick={decrementQuantity}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" className="h-6 w-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4"></path>
                                                </svg>
                                            </div>
                                            <input
                                                min="1"
                                                autoComplete="off"
                                                type="number"
                                                id="Quantity"
                                                className="h-10 w-16 border-transparent text-center bg-white"
                                                value={quantity}
                                                onChange={handleQuantityChange}
                                            />
                                            <div className="cursor-pointer w-6 h-6 flex items-center justify-center" onClick={incrementQuantity}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            ) : (
                                <section>
                                    <div className="border bg-white rounded-md">
                                        <div className="text-left px-3 flex items-center">
                                            <div className="_order_header_step_circle mr-2">2</div>
                                            <h2 className="text-lg text-black py-2 font-bold fb-normal"> Account Info </h2>
                                        </div>
                                        <hr />
                                        <div className="p-3">
                                            {inputFieldsPlayerId.map((field, index) => (
                                                <div key={index} className="p-2 pb-0">
                                                    <label>{field.label}</label>
                                                    <input type={field.type} name={field.name} placeholder={field.label} onChange={handleAccountInfoChange} className="form-input relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-2.5 shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset dark:ring-black-900 focus:ring-2 focus:ring-black-900 dark:focus:ring-black-900" required={field.required} />
                                                </div>
                                            ))}
                                            <div className="w-full text-left ml-1">
                                                <a href="#" target="_blank" className="text-left text-lg flex items-start info-text" style={{ color: 'rgb(0, 0, 238)' }}>{errorPlayerID && <p className="text-red-500">{errorPlayerID}</p>}</a>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}
                            <section>
                                <div className="select-server bg-white border mt-2" style={{ borderRadius: '0.375rem', borderWidth: '1px' }}>
                                    <div className="text-left px-3 flex items-center">
                                        <div className="_order_header_step_circle mr-2">3</div>
                                        <h2 className="text-lg text-black py-2 font-bold fb-normal"> Payment Methods </h2>
                                    </div>
                                    <hr />
                                    <div className="flex justify-center py-3 px-2">
                                        <div className="w-full">
                                            <div className="m-1">
                                                <label htmlFor="nagad"
                                                    className={`mb-0 w-full list-group-item pt-2 cursor-pointer ${selectedPaymentMethod === 'wallet' ? 'checked' : ''}`}
                                                    style={{
                                                        display: 'block',
                                                        fontSize: '11px',
                                                        position: 'relative',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    {selectedPaymentMethod === 'wallet' ? (
                                                        <span className="absolute left-0 element-check-label" style={{ color: 'rgb(255, 255, 255)' }}>L</span>
                                                    ) : (
                                                        <span className="absolute left-0" style={{ color: 'rgb(255, 255, 255)' }}>L</span>
                                                    )}
                                                    <img src={walletImage} alt="nagad" className="p-2" style={{ height: '6rem' }} />
                                                    <input id="nagad"
                                                        name="send" type="radio"
                                                        value="wallet"

                                                        checked={selectedPaymentMethod === 'wallet'}
                                                        onChange={handlePaymentMethodChange}
                                                        className="absolute" style={{ visibility: 'hidden' }} />
                                                    <div className="bg-gray-300 text-left">
                                                        <p className="text-xs p-1 capitalize fb-normal"> Pay from Wallet </p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-full">
                                            <div className="m-1">
                                                <label htmlFor="bkash"
                                                    className={`mb-0 w-full list-group-item pt-2 cursor-pointer ${selectedPaymentMethod === 'instant' ? 'checked' : ''}`}
                                                    style={{
                                                        display: 'block',
                                                        fontSize: '11px',
                                                        position: 'relative',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    {selectedPaymentMethod === 'instant' ? (
                                                        <span className="absolute left-0 element-check-label" style={{ color: 'rgb(255, 255, 255)' }}>L</span>
                                                    ) : (
                                                        <span className="absolute left-0" style={{ color: 'rgb(255, 255, 255)' }}>L</span>
                                                    )}
                                                    <img src={PaymentImage} alt="bkash" className="p-2" style={{ height: '6rem' }} />
                                                    <input id="bkash" name="send"
                                                        value="instant"

                                                        checked={selectedPaymentMethod === 'instant'}
                                                        onChange={handlePaymentMethodChange}
                                                        type="radio" className="absolute"
                                                        style={{ visibility: 'hidden' }} />
                                                    <div className="bg-gray-300 text-left">
                                                        <p className="text-xs p-1 capitalize fb-normal"> Instant Pay </p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pb-5">
                                        {/*display button based on access*/}

                                        <div className="col-md-12 text-left px-3">
                                            {!user ? (
                                                <div>
                                                    <div className="col-md-12 text-left px-3">
                                                        <div className="fb-normal text-xs flex items-center mb-3" style={{ color: 'gray' }}>
                                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa h-4 w-4 mr-1 fa-info-circle fa-w-16 fa-2x">
                                                                <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                                                            </svg>
                                                            আপনার অ্যাকাউন্ট ব্যালেন্স
                                                            <div style={{ minWidth: '100px' }}>
                                                                <span className="flex items-center">
                                                                    <p className="pl-2 text-pink-500 font-bold fb">৳ 0.00</p>
                                                                    <div className="border ml-2 p-1 rounded cursor-pointer">
                                                                        <svg viewBox="0 0 24 24" style={{ width: '16px', height: '16px' }}>
                                                                            <path fill="currentColor" d="M2 12C2 16.97 6.03 21 11 21C13.39 21 15.68 20.06 17.4 18.4L15.9 16.9C14.63 18.25 12.86 19 11 19C4.76 19 1.64 11.46 6.05 7.05C10.46 2.64 18 5.77 18 12H15L19 16H19.1L23 12H20C20 7.03 15.97 3 11 3C6.03 3 2 7.03 2 12Z"></path>
                                                                        </svg>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <p className="fb-normal text-xs flex items-center mb-3" style={{ color: 'gray' }}>
                                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa h-4 w-4 mr-1 fa-info-circle fa-w-16 fa-2x">
                                                                <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                                                            </svg>
                                                            প্রোডাক্ট কিনতে আপনার প্রয়োজন
                                                            <span className="text-pink-500 font-bold fb" style={{ padding: '0px 4px' }}>৳ {totalAmount}</span>
                                                        </p>
                                                        <p className="fb-normal text-xs flex items-center text-orange-400 pb-2 pt-1">
                                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa h-4 w-4 mr-1 fa-info-circle fa-w-16 fa-2x">
                                                                <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                                                            </svg>
                                                            Please Login To Purchase
                                                        </p>
                                                    </div>
                                                    <Link to="/login" className=""><button className="justify-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 px-2.5 py-2.5 shadow-sm text-white dark:text-gray-900 bg-primary-500 hover:bg-primary-600 disabled:bg-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:disabled:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 dark:focus-visible:outline-primary-400 inline-flex items-center my-2 w-full text-center"> Login </button></Link>
                                                </div>
                                            ) : (
                                                <div className="col-md-12 text-left px-3">
                                                    <div className="fb-normal text-xs flex items-center mb-3" style={{ color: 'gray' }}>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa h-4 w-4 mr-1 fa-info-circle fa-w-16 fa-2x">
                                                            <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                                                        </svg>
                                                        আপনার অ্যাকাউন্ট ব্যালেন্স
                                                        <div style={{ minWidth: '100px' }}>
                                                            <span className="flex items-center">
                                                                <p className="pl-2 text-pink-500 font-bold fb">৳  {wallet && wallet.balance}</p>
                                                                <div className="border ml-2 p-1 rounded cursor-pointer">
                                                                    <svg viewBox="0 0 24 24" style={{ width: '16px', height: '16px' }}>
                                                                        <path fill="currentColor" d="M2 12C2 16.97 6.03 21 11 21C13.39 21 15.68 20.06 17.4 18.4L15.9 16.9C14.63 18.25 12.86 19 11 19C4.76 19 1.64 11.46 6.05 7.05C10.46 2.64 18 5.77 18 12H15L19 16H19.1L23 12H20C20 7.03 15.97 3 11 3C6.03 3 2 7.03 2 12Z"></path>
                                                                    </svg>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className="fb-normal text-xs flex items-center mb-3" style={{ color: 'gray' }}>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa h-4 w-4 mr-1 fa-info-circle fa-w-16 fa-2x">
                                                            <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                                                        </svg>
                                                        প্রোডাক্ট কিনতে আপনার প্রয়োজন
                                                        <span className="text-pink-500 font-bold fb" style={{ padding: '0px 4px' }}>৳ {totalAmount}</span>
                                                    </p>
                                                    <div>

                                                        <button className="align-middle bg-pink-500 hover:bg-pink-400 text-center px-4 py-2 text-white text-sm font-semibold rounded inline-block shadow-lg w-full srgameshop-btn"
                                                            onClick={handleBuyNow} type="button" disabled={!selectedPaymentMethod || loading}>

                                                            {loading ? (
                                                                <div className="flex-center">
                                                                    <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2" />
                                                                    Loading
                                                                </div>
                                                            ) : (
                                                                "Buy Now"
                                                            )}
                                                        </button>
                                                        {error && <p className="text-red-500">{error}</p>}
                                                    </div>
                                                </div>

                                            )}
                                        </div >
                                    </div >
                                </div>
                            </section>
                        </div>
                    </form>
                </div>
                <div className="mt-3 md:mt-8 bg-white border rounded-md">
                    <h1 className="font-bold p-2"> Rules &amp; Conditions </h1>
                    <hr />
                    {product.description ? (
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: product.description }} />
                    ) : (
                        <p>No description available.</p>
                    )}                </div>
            </div>
        </>
    );
}
