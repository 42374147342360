import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from './../config';

export default function Transactions() {
    const user = JSON.parse(localStorage.getItem('user-info'));
    const userId = user.id;

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        fetchTransactions(currentPage);
        return () => window.removeEventListener('resize', handleResize);
    }, [currentPage]);

    const fetchTransactions = (page) => {
        setLoading(true);
        fetch(`${config.baseURL}api/user-transactions/${userId}?page=${page}`)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.message || 'Network response was not ok');
                    });
                }
                return response.json();
            })
            .then(data => {
                if (data.data.length === 0) {
                    setError('No transactions found for this user.');
                } else {
                    setTransactions(data.data);
                    setTotalPages(data.last_page);
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error.message || 'Error fetching transactions');
                setLoading(false);
            });
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const containerStyle = isMobile ? { marginTop: '15%' } : { marginTop: '4%' };

    return (
        <div>
            <section className="container m-auto" style={containerStyle}>
                <div className="p-2">
                    <div className="border bg-white min-h-screen">
                        <div className="text-left px-3 flex items-center">
                            <svg
                                className="mr-2"
                                viewBox="0 0 24 24"
                                style={{ width: "24px", height: "24px" }}
                            >
                                <path
                                    fill="currentColor"
                                    d="M11 15H17V17H11V15M9 7H7V9H9V7M11 13H17V11H11V13M11 9H17V7H11V9M9 11H7V13H9V11M21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H19C20.1 3 21 3.9 21 5M19 5H5V19H19V5M9 15H7V17H9V15Z"
                                ></path>
                            </svg>
                            <h2 className="text-lg text-black py-2 font-normal">
                                All Transactions
                            </h2>
                        </div>
                        <hr />
                        {loading ? (
                            <div className="text-center p-5">Loading...</div>
                        ) : error ? (
                            <div className="text-center p-5 text-red-500">{error}</div>
                        ) : (
                            <div className="overflow-x-auto">
                                <table className="border-collapse w-full">
                                    <thead>
                                        <tr>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300">
                                                TRX ID
                                            </th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300">
                                                Amount Due
                                            </th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300">
                                                Amount Paid
                                            </th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300">
                                                PAYMENT TYPE
                                            </th>
                                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactions.map((transaction, index) => (
                                            <tr key={index}>
                                                <td className="p-3 text-gray-800 text-center border border-b">
                                                    {transaction.trx_id}
                                                </td>
                                                <td className="p-3 text-gray-800 text-center border border-b">
                                                    {transaction.amount_due}
                                                </td>
                                                <td className="p-3 text-gray-800 text-center border border-b">
                                                    {transaction.amount_paid}
                                                </td>
                                                <td className="p-3 text-gray-800 text-center border border-b">
                                                    {transaction.payment_type}
                                                </td>
                                                <td className="p-3 text-gray-800 text-center border border-b">
                                                    {transaction.status}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        )}
                        <div className="flex text-gray-700 justify-end">
                            <button
                                className="h-10 w-10 mr-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer"
                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-left w-6 h-6"
                                >
                                    <polyline points="15 18 9 12 15 6"></polyline>
                                </svg>
                            </button>
                            <span className="inline-flex items-center font-medium rounded-md text-xs px-2 py-1 bg-primary-500 dark:bg-primary-400 text-white dark:text-gray-900">
                                {currentPage}
                            </span>
                            <button
                                className="h-10 w-10 ml-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer"
                                onClick={() => setCurrentPage(prev => (prev < totalPages ? prev + 1 : prev))}
                                disabled={currentPage === totalPages}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-right w-6 h-6"
                                >
                                    <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
