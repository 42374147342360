import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
import config from '../../config';
import logo from '../../assets/img/1711652676.png'; // Adjust the path as needed
import userPassport from '../../assets/img/default.png'; // Adjust the path as needed


export default function Header({ items, setSearchData }) {
    const [query, setQuery] = useState('');
    const user = JSON.parse(localStorage.getItem('user-info'));
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const navigate = useNavigate();
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    async function search(event) {
        event.preventDefault(); // Prevents form submission

        try {
            const response = await fetch(`${config.baseURL}api/search/${query}`);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const result = await response.json();

            if (result) {
                //console.log('No result found:', result);
                //exit;
                setSearchData(result);
                navigate('/search');
            } else {
                //console.log('No result found:', result);
                navigate('/search');
            }
        } catch (error) {
            console.error('Error during search:', error);
            navigate('/search');
        }
    }

    const handleSidebarToggle = () => {
        setIsSidebarVisible(!isSidebarVisible);
    }



    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector('.header');
            if (window.scrollY > 50) {
                header.classList.add('scrolled');
            } else {
                header.classList.remove('scrolled');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const containerStyle = isMobile ? { right: '50%', transform: 'translateX(50%)' } : {};

    return (
        <>
            <div id="__nuxt" data-v-app="">

                <div className="body-bg">
                    <div className="nuxt-loading-indicator" style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        left: '0px',
                        pointerEvents: 'none',
                        width: 'auto',
                        height: '3px',
                        opacity: 0,
                        background: 'repeating-linear-gradient(to right, rgb(0, 220, 130) 0%, rgb(52, 205, 254) 50%, rgb(0, 71, 225) 100%)',
                        transform: 'scaleX(0)',
                        transformOrigin: 'left center',
                        transition: 'transform 0.1s ease 0s, height 0.4s ease 0s, opacity 0.4s ease 0s',
                        zIndex: 999999
                    }}>
                    </div>
                    <div>
                        <div className="header" >
                            <div className="container m-auto p-2 py-3 md:py-5 md:px-0">
                                <nav className="flex items-center justify-between">
                                    <a aria-current="page" href="/" className="router-link-active router-link-exact-active">
                                        <img
                                            src={logo}
                                            alt="Logo"
                                            srcSet={`${logo} 1x, ${logo} 2x`}
                                            className="w-28 md:w-48 logo"
                                            style={{ height: '50px', objectFit: 'contain' }}
                                        />
                                    </a>
                                    {/* Search bar */}
                                    <form onSubmit={search} className={`search-form flex-grow mx-4 ${isSearchActive ? 'active' : ''}`}>
                                        <div className="relative w-full">
                                            <input
                                                type="text"
                                                value={query}
                                                onChange={(e) => setQuery(e.target.value)}
                                                placeholder="Search..."
                                                className="border rounded px-4 py-2 w-full pl-10 search-input"
                                                style={{ color: 'black', borderColor: '#ff4051', paddingLeft: '30px' }}
                                                required
                                            />
                                            <div className="absolute inset-y-0 right-3 flex items-center justify-center search-icon" style={containerStyle} onClick={() => setIsSearchActive(!isSearchActive)}>
                                                <svg className="h-8 w-8 md:h-5 md:w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ color: '#ff4051', paddingLeft: '2px', paddingRight: '5px' }}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M16.65 16.65A7.5 7.5 0 1111.5 4.5a7.5 7.5 0 015.15 12.15z" />
                                                </svg>
                                            </div>



                                        </div>
                                    </form>
                                    <div className="flex items-center">
                                        <nav className="text-left hidden md:block">
                                            <div className="w-full flex-grow flex items-center lg:w-auto">
                                                <div className="text-sm flex-grow animated jackinthebox mx-auto">
                                                    <a href="/topup" className="block inline-block text-md font-bold mx-2 p-1 rounded-lg fb-normal link">
                                                        Topup
                                                    </a>
                                                    <Link className="block inline-block text-md font-bold mx-2 p-1 rounded-lg fb-normal link" to="/contact">Contact US</Link>
                                                </div>
                                            </div>
                                        </nav>
                                        <div className="flex items-center">
                                            {!user ? <Link to="/login" className="btn-pro btn-pro-login rounded mx-2 border-2 text-primary-500 border-primary-500">
                                                Login
                                            </Link> : ''}
                                            {!user ? <Link to="/register" className="btn-pro btn-pro-login btn-register rounded ml-2 border-2 border-primary-500 bg-primary-500 text-white">
                                                Register
                                            </Link> : ''}
                                        </div>
                                        {
                                            user && <>
                                                <button onClick={handleSidebarToggle} className="flex text-xs md:px-4 py-1.5 border rounded-full text-black top-profile font-primary">
                                                    <div className="relative">
                                                        <div className="flex items-center cursor-pointer px-2 duration-75">
                                                            <div className="mr-2">
                                                            </div>
                                                            <img src={userPassport} className="mr-1" alt="Profile" />
                                                            <p className="_body2 text-gray-500 capitalize select-none user-child name_user">
                                                                {user.name.length > 12 ? `${user.name.substring(0, 12)}...` : user.name}                                                            </p>
                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg" className="text-gray-500 duration-150 user-child">
                                                                <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </button>
                                            </>
                                        }
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isSidebarVisible && <Sidebar />}
        </>
    )
}
