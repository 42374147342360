import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import config from '../config';

export default function Register() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [errors, setErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [phone, setPhone] = useState("");
    const navigate = useNavigate();

    const validatePassword = (password) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/.test(password);
    };

    const signup = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setLoading(true);
        setErrors([]);
        setSuccessMessage("");

        /* if (!validatePassword(password)) {
             setErrors(["Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character."]);
             setLoading(false);
             return;
         }*/

        if (password !== confirmPassword) {
            setErrors(["Password and Confirm Password must match."]);
            setLoading(false);
            return;
        }

        const item = {
            name,
            email,
            password,
            password_confirmation: confirmPassword,
            is_active: true,
            phone,
        };

        try {
            const response = await fetch(`${config.baseURL}api/register`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(item)
            });

            if (!response.ok) {
                const errorData = await response.json();
                setErrors(errorData.error ? [errorData.error] : ["Failed to register. Please try again later."]);
                setLoading(false);
                return;
            }

            setSuccessMessage("Registration successful! you can login now.");
            setName("");
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            setPhone("");

        } catch (error) {
            setErrors(["Failed to register. Please try again later."]);
            console.error("Registration Error:", error);
            window.alert(error.message); // Replace with better error handling
        } finally {
            setLoading(false);
        }
    };
    const googleLogin = () => {
        setLoading2(true);

        window.location.href = `${config.baseURL}api/auth/google`;
    };
    useEffect(() => {
        const handleGoogleCallbackRegister = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const email = urlParams.get('email');
                const name = urlParams.get('name');
                const password = urlParams.get('password');
                const google_id = urlParams.get('google_id');

                if (!email) {
                    throw new Error('No authorization code found.');
                }

                const response = await fetch(`${config.baseURL}api/auth/google/callbackregister?email=${email}&name=${name}&password=${password}&google_id=${google_id}`);
                const data = await response.json();

                if (response.ok) {
                    setSuccessMessage('Registration successful! Please check your email to verify your account.');
                } else {
                    setErrors([data.error || 'Failed to register with Google. Please try again.']);
                }
            } catch (error) {
                console.error('Error fetching user data after Google registration:', error);
                setErrors(['Failed to register with Google. Please try again.']);
            } finally {
                setLoading2(false);
            }
        };

        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        const name = urlParams.get('name');
        const password = urlParams.get('password');
        const google_id = urlParams.get('google_id');

        if (email && name && password && google_id) {
            handleGoogleCallbackRegister();
        }
    }, [navigate]);





    return (
        <>
            <div className="primary-section px-5 md:px-0 body-content" >
                <div className="login-form px-4 md:px-2 pb-3 mx-auto">
                    <div className="w-auto px-0 md:px-3 pt-5 pb-1">
                        <h1 className="text-2xl font-bold">Register</h1>
                        <div className="text-center my-3">
                            <div className="social-login">
                                <button type="button"
                                    onClick={googleLogin}
                                    disabled={loading2}
                                    className="focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 px-2.5 py-2.5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 text-gray-900 dark:text-white bg-white hover:bg-gray-50 disabled:bg-white dark:bg-gray-900 dark:hover:bg-gray-800/50 dark:disabled:bg-gray-900 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400 inline-flex items-center">
                                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                                        <g fill="#000" fillRule="evenodd">
                                            <path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path>
                                            <path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c-1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path>
                                            <path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path>
                                            <path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path>
                                            <path fill="none" d="M0 0h18v18H0z"></path>
                                        </g>
                                    </svg>


                                    {loading2 ? (
                                        <div className="flex items-center">
                                            <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2" style={{ borderBottomCcolor: 'red', borderLeftColor: 'red' }} />
                                            Loading
                                        </div>
                                    ) : (
                                        "Register with Google"
                                    )}
                                </button>
                                <button className="hidden bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs" type="button">
                                    <svg aria-hidden="true" data-prefix="fab" data-icon="facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 mr-2">
                                        <path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
                                    </svg>

                                    Register with Facebook
                                </button>
                                {/* Other social login buttons */}
                            </div>
                            <div className="flex justify-between items-center pt-5">
                                <hr className="w-1/5 px-2" />
                                <h1 className="text-gray-500 w-3/5 font-primary px-2 text-sm"> Or sign up with credentials </h1>
                                <hr className="w-1/5 px-2" />
                            </div>
                        </div>

                        {errors && errors.length > 0 && (
                            <div className="alert alert-danger relative" style={{ backgroundColor: '#ffcccc', color: '#cc0000', textAlign: 'center', padding: '10px', borderRadius: '5px' }}>
                                <button
                                    className="absolute top-0 right-0 m-2 focus:outline-none"
                                    onClick={() => setErrors([])}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 cursor-pointer"
                                        viewBox="0 0 20 20"
                                        style={{ fill: '#cc0000' }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M14.95 5.95a.75.75 0 0 1 0 1.06l-4.95 4.95 4.95 4.95a.75.75 0 0 1-1.06 1.06l-4.95-4.95-4.95 4.95a.75.75 0 0 1-1.06-1.06l4.95-4.95-4.95-4.95a.75.75 0 0 1 1.06-1.06l4.95 4.95 4.95-4.95a.75.75 0 0 1 1.06 0z"
                                        />
                                    </svg>
                                </button>
                                {errors.map((error, index) => (
                                    <p key={index}>{error}</p>
                                ))}
                            </div>
                        )}

                        {successMessage && (
                            <div className="alert alert-success relative" style={{ backgroundColor: '#d4edda', borderColor: '#c3e6cb', color: '#155724', textAlign: 'center', padding: '10px', borderRadius: '5px' }}>
                                {successMessage}
                            </div>
                        )}
                        <div className="relative py-1">
                            <label className="label-title">Name</label>
                            <input type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Name" className="form-input relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-2.5 shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset dark:ring-black-900 focus:ring-2 focus:ring-black-900 dark:focus:ring-black-900" />
                        </div>
                        <div className="relative py-1">
                            <label className="label-title">Phone</label>
                            <input type="text"
                                id="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Phone" className="form-input py-1 block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-2.5 shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset dark:ring-black-900 focus:ring-2 focus:ring-black-900 dark:focus:ring-black-900" />
                        </div>
                        <div className="relative py-1">
                            <label className="label-title">Email</label>
                            <input type="text"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email" className="form-input relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-2.5 shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset dark:ring-black-900 focus:ring-2 focus:ring-black-900 dark:focus:ring-black-900" />
                        </div>
                        <div className="relative py-1">
                            <label className="label-title">Password</label>
                            <input type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="off" placeholder="Password" className="form-input relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-2.5 shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset dark:ring-black-900 focus:ring-2 focus:ring-black-900 dark:focus:ring-black-900" />
                        </div>
                        <div className="relative py-1">
                            <label className="label-title">Confirm Password</label>
                            <input type="password"
                                id="confirm_password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                autoComplete="off" placeholder="Password" className="form-input relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-2.5 shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset dark:ring-red-400 focus:ring-2 focus:ring-black-500 dark:focus:ring-black-400" />
                        </div>
                        <div className="text-center">
                            <button type="button" name="register" onClick={signup} disabled={loading} className="justify-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 px-2.5 py-2.5 shadow-sm text-white dark:text-gray-900 bg-primary-500 hover:bg-primary-600 disabled:bg-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:disabled:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 dark:focus-visible:outline-primary-400 inline-flex items-center my-2 w-full text-center">
                                {loading ? (
                                    <div className="flex items-center">
                                        <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2" />
                                        Loading
                                    </div>

                                ) : (
                                    "Register"
                                )}
                            </button>

                        </div>
                    </div>
                    <div className="mb-5 text-center subtitle-4 font-primary font-normal game-name">
                        Already a member? <a href="/login" className="text-pink-500 font-primary font-normal">Login Now</a>
                    </div>
                </div>
            </div>

        </>
    );
}
