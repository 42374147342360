import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import config from './../config';

export default function WalletTopUpPaymentSuccess() {
    const navigate = useNavigate();
    const location = useLocation();
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            const searchParams = new URLSearchParams(location.search);
            const orderId = searchParams.get('trx_id');

            if (orderId) {
                try {
                    const response = await fetch(`${config.baseURL}api/wallet-topup-payment-details/${orderId}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setPaymentDetails(data);
                } catch (error) {
                    console.error('Error fetching payment details:', error);
                }
            }
        };

        fetchPaymentDetails();
    }, [location.search]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!paymentDetails) {
        return <div>Loading...</div>;
    }

    const {
        trx_id,
        dateTime,
        totalAmount,
        balance,
        payment_method,
        user
    } = paymentDetails;

    const containerStyle = isMobile
        ? { marginTop: '18%' }
        : { marginTop: '0%' }; // Adjust marginTop value as needed

    return (
        <>
            <body className="w-full min-h-screen sm:h-auto sm:p-12 sm:flex sm:items-center sm:justify-center" style={containerStyle}>
                <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"', backgroundColor: '#ffffff', color: '#718096', lineHeight: '1.4', margin: 0, padding: 0 }}>
                    <table width="100%" cellSpacing="0" cellPadding="0" role="presentation" style={{ backgroundColor: '#edf2f7', margin: 0, padding: 0, width: '100%' }}>
                        <tbody>
                            <tr>
                                <td align="center" style={{ backgroundColor: '#edf2f7', margin: 0, padding: 0 }}>
                                    <table width="100%" cellSpacing="0" cellPadding="0" role="presentation" style={{ margin: 0, padding: 0, maxWidth: '600px', width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: '#ffffff', border: '1px solid #e8e5ef', borderRadius: '2px', margin: '0 auto', padding: '32px' }}>

                                                    <p>Thank you for your wallet Top up. Your add money request was successful.</p>
                                                    <h3 style={{ color: '#7f54b3' }}>[Wallet Top Up #{trx_id}] ({dateTime})</h3>

                                                    <table cellSpacing="0" cellPadding="6" width="100%" style={{ borderCollapse: 'collapse', border: '1px solid #ccc', marginTop: '16px' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ padding: '12px', border: '1px solid #ccc' }} colSpan="2">Subtotal: </td>
                                                                <td style={{ padding: '12px', border: '1px solid #ccc' }}>{totalAmount}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ padding: '12px', border: '1px solid #ccc' }} colSpan="2">Payment Method</td>
                                                                <td style={{ padding: '12px', border: '1px solid #ccc' }}>{payment_method}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ padding: '12px', border: '1px solid #ccc' }} colSpan="2">Total</td>
                                                                <td style={{ padding: '12px', border: '1px solid #ccc' }}>{totalAmount}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <h3 style={{ color: '#7f54b3' }}>Available Balance</h3>

                                                    <table cellSpacing="0" cellPadding="6" width="100%" style={{ borderCollapse: 'collapse', border: '1px solid #ccc', marginTop: '16px' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ padding: '12px', border: '1px solid #ccc' }} colSpan="2">Balance: </td>
                                                                <td style={{ padding: '12px', border: '1px solid #ccc' }}>{balance}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>


                                                    <h3 style={{ color: '#7f54b3', marginTop: '16px' }}>Billing Details</h3>
                                                    <table cellSpacing="0" cellPadding="6" width="100%" style={{ borderCollapse: 'collapse', border: '1px solid #ccc' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ padding: '12px', border: '1px solid #ccc' }}>{user.name}<br />{user.email}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </body>
        </>
    );
}
